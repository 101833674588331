import Repository from '@/repository/Index';
import { AxiosPromise } from 'axios';

export default class UtilRepository {
  // Retrieve country codes, flags and more
  public static countries(): AxiosPromise {
    return Repository.get('/app-data/countries');
  }

  // Get currencies
  public static currencies(): AxiosPromise {
    return Repository.get('/app-data/currencies');
  }

  // Get document vat references
  public static vatReference(): AxiosPromise {
    return Repository.get('/app-data/vat-references');
  }
}
