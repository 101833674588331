import { AxiosPromise } from 'axios';
import Repository from '@/repository/Index';
import type Locale from '@/enums/config/Locale';

export default class TranslationsRepository {
  // Get all translations endpoint
  public static fetchTranslations(locale?: Locale): AxiosPromise {
    return Repository.get('/app-data/translations', { params: { locale } });
  }

  // Map laravel validation messages to vee-validate format - helper function
  public static validatorDictionary(translations: Record<string, string>): Record<string, string> {
    return {
      alpha: TranslationsRepository.laravelMessage(translations['validation.alpha']),
      alpha_num: TranslationsRepository.laravelMessage(translations['validation.alpha_num']),
      alpha_dash: TranslationsRepository.laravelMessage(translations['validation.alpha_dash']),
      alpha_spaces: TranslationsRepository.laravelMessage(translations['validation.alpha_spaces']),
      between: TranslationsRepository.laravelMessage(translations['validation.between.string'], {
        min: 'min',
        max: 'max',
      }),
      confirmed: TranslationsRepository.laravelMessage(translations['validation.confirmed']),
      color: TranslationsRepository.laravelMessage(translations['validation.invalid_color']),
      digits: TranslationsRepository.laravelMessage(translations['validation.digits'], { digits: 'length' }),
      dimension: TranslationsRepository.laravelMessage(translations['validation.dimensions']),
      dimensions: TranslationsRepository.laravelMessage(translations['validation.dimensions']),
      email: TranslationsRepository.laravelMessage(translations['validation.email']),
      excluded: TranslationsRepository.laravelMessage(translations['validation.not_in']),
      ext: TranslationsRepository.laravelMessage(translations['validation.file']),
      image: TranslationsRepository.laravelMessage(translations['validation.image']),
      integer: TranslationsRepository.laravelMessage(translations['validation.integer']),
      regex: TranslationsRepository.laravelMessage(translations['validation.regex']),
      required_if: TranslationsRepository.laravelMessage(translations['validation.required']),
      required: TranslationsRepository.laravelMessage(translations['validation.required']),
      oneOf: TranslationsRepository.laravelMessage(translations['validation.in']),
      numeric: TranslationsRepository.laravelMessage(translations['validation.numeric']),
      mimes: TranslationsRepository.laravelMessage(translations['validation.file']),
      mimetypes: TranslationsRepository.laravelMessage(translations['validation.forbidden_file']),
      filesize: TranslationsRepository.laravelMessage(translations['validation.lte.file'], {
        value: 'size',
      }),
      length: TranslationsRepository.laravelMessage(translations['validation.size.numeric'], {
        size: 'length',
      }),
      max_value: TranslationsRepository.laravelMessage(translations['validation.max.numeric'], { max: 'max' }),
      max: TranslationsRepository.laravelMessage(translations['validation.max.string'], { max: 'length' }),
      min_value: TranslationsRepository.laravelMessage(translations['validation.min.numeric'], { min: 'min' }),
      min: TranslationsRepository.laravelMessage(translations['validation.min.string'], { min: 'length' }),
      size: TranslationsRepository.laravelMessage(translations['validation.max.file'], { max: 'size' }),

      // Custom validation error messages
      registration_number: TranslationsRepository.laravelMessage(translations['validation.registration_number']),
      phone_number: TranslationsRepository.laravelMessage(translations['validation.phone_number']),
      iso: TranslationsRepository.laravelMessage(translations['validation.iso']),
      double: TranslationsRepository.laravelMessage(translations['validation.float']),
      password_strength: TranslationsRepository.laravelMessage(translations['validation.password_strength']),
      website: TranslationsRepository.laravelMessage(translations['validation.website']),
      record_number_format: TranslationsRepository.laravelMessage(translations['validation.record_number_format']),
      iterator_y: TranslationsRepository.laravelMessage(translations['validation.iterator_y']),
      iterator_m: TranslationsRepository.laravelMessage(translations['validation.iterator_m']),
      iterator_d: TranslationsRepository.laravelMessage(translations['validation.iterator_d']),
      decimal: TranslationsRepository.laravelMessage(translations['validation.float']),
      decimal_places_limit: TranslationsRepository.laravelMessage(translations['validation.decimal_places_limit'], {
        count: 'count',
      }),
    };
  }

  // Read and transform laravle validation message - helper function
  public static laravelMessage(message: string, overrides?: any) {
    if (message) {
      message = message.replace(':attribute', '{_field_}');

      if (overrides) {
        Object.keys(overrides).forEach((key: string) => {
          message = message.replace(`:${key}`, `{${overrides[key]}}`);
        });
      }
      return message;
    }

    return 'No validation message!';
  }
}
