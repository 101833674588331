import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_COMPANY from '@/constants/mocks/company/Company';
import DEFAULT_RECURRING_DOCUMENT_FORM from '@/constants/mocks/forms/RecurringDocument';
import DateFormat from '@/enums/config/DateFormat';
import type RecurringDocumentType from '@/enums/config/document/RecurringType';
import type IBank from '@/interfaces/bank/IBank';
import type ICompany from '@/interfaces/company/ICompany';
import type { IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import type IRecurringDocument from '@/interfaces/document/IRecurringDocument';
import type IStock from '@/interfaces/stock/IStock';
import BankModule from '@/store/modules/Bank';
import CompanyModule from '@/store/modules/Company';

@Component
export default class InitBlankRecurringMixin extends Vue {
  public InitBlankRecurringMixin(type: RecurringDocumentType): IRecurringDocument {
    const document: IRecurringDocument = cloneDeep(DEFAULT_RECURRING_DOCUMENT_FORM);

    const company: ICompany = CompanyModule.getCompany || DEFAULT_COMPANY;
    const documentPreferences: IDocumentPreference = company.document_preferences[type];
    const paymentMethods: IBank[] = BankModule.getBanks;

    const lateFee = company.use_late_fees ? company.default_late_fee_rate : null;
    const dueDays = documentPreferences.default_due_days;

    document.lang = company.default_document_locale || DEFAULT_COMPANY.default_document_locale;
    document.type = type;
    document.currency_code =
      company.default_document_currency_code || company.currency_code || DEFAULT_COMPANY.default_document_currency_code;

    document.tax_by_group = company.tax_by_group;

    document.number_format = documentPreferences.number_format;

    document.date = this.parseNowToFormat(DateFormat.LARAVEL);
    document.due_date = dueDays ? this.addPeriodToFormat(document.date, DateFormat.LARAVEL, dueDays, 'days') : '';
    document.late_fee_percent = lateFee;

    document.logo = company.logo;
    document.color = company.default_document_accent_color;

    document.sender = {
      ...DEFAULT_RECURRING_DOCUMENT_FORM.sender,
      name: company.name,
      registration_number: company.registration_number,
      vat_registration_number: company.vat_registration_number,

      payment_methods: cloneDeep(paymentMethods),

      email: company.email,
      address: company.address,
      country: company.country,
      website: company.website,

      phone_prefix: company.phone_prefix || DEFAULT_COMPANY.phone_prefix,
      phone_number: company.phone_number,

      notes: documentPreferences.default_supplier_notes,
    };

    document.single_vat = CompanyModule.isTaxPayer
      ? company.use_taxes
        ? company.default_tax_rate
        : DEFAULT_COMPANY.default_tax_rate
      : 0;

    document.items.forEach((e: IStock) => (e.vat_percent = document.single_vat ?? 0));
    document.notes = documentPreferences.default_notes;

    return cloneDeep(document);
  }
}
