import TEST_DATA from '@/constants/config/TestData';
import DEFAULT_DOCUMENT_SETTINGS from '@/constants/mocks/company/DocumentSettings';
import type IStockForm from '@/interfaces/forms/stock/IStockForm';

import useEnv from '@/services/Env';

const { isDev } = useEnv();

const DEFAULT_STOCK_FORM: IStockForm = {
  uuid: '',
  sku: isDev ? TEST_DATA.stock.sku : '',

  name: isDev ? TEST_DATA.stock.name : '',
  description: isDev ? TEST_DATA.stock.description : '',

  measurement: '',
  quantity: 1,

  discount: 0,
  vat_percent: DEFAULT_DOCUMENT_SETTINGS.use_taxes ? DEFAULT_DOCUMENT_SETTINGS.default_tax_rate : 21,

  price: 0.0,
  price_with_vat: 0.0,
};

export default DEFAULT_STOCK_FORM;
