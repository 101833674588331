import Vue from 'vue';
import app from '@/main';
import store from '@/store';
import isEqual from 'lodash-es/isEqual';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import LocalStorage from '@/enums/config/LocalStorage';
import DocumentRoute from '@/enums/config/document/Route';
import DashboardRouteName from '@/enums/config/router/Dashboard';
import ProfileRouteName from '@/enums/config/router/Profile';
import type { Location, Route } from 'vue-router';
import CompanyModule from '@/store/modules/Company';
import DocumentModule from '@/store/modules/Document';
import GenericModule from '@/store/modules/Generic';
import PartnerModule from '@/store/modules/Partner';
import RecurringModule from '@/store/modules/Recurring';
import ReferenceModule from '@/store/modules/Reference';
import StockModule from '@/store/modules/Stock';
import TranslationModule from '@/store/modules/Translation';
import UserModule from '@/store/modules/User';
import UtilModule from '@/store/modules/Util';
import AuthMixin from '@/mixins/Auth';
import ChartMixin from '@/mixins/Chart';
import DocumentMixin from '@/mixins/Document';
import ParterMixin from '@/mixins/Partner';
import RecurringMixin from '@/mixins/Recurring';
import StockMixin from '@/mixins/Stock';

@Module({
  store,
  name: 'App',
  namespaced: true,
  dynamic: true,
})
class App extends VuexModule {
  private isReady = false;
  private isMaintenance = false;

  public get getIsReady(): boolean {
    return this.isReady;
  }

  public get getIsMaintenance(): boolean {
    return this.isMaintenance;
  }

  // Set application is in maintenance mode
  @Action({ commit: 'UPDATE_IS_MAINTENANCE' })
  public async SET_IS_MAINTENANCE(): Promise<boolean> {
    return true;
  }

  // Initialize application, fetch - user, translation and company data
  @Action({ commit: 'UPDATE_IS_READY' })
  public async SET_IS_READY(): Promise<boolean> {
    if (this.isReady) {
      return true;
    }

    // Fetch CSRF cookie and retry if failed
    if (!(await new AuthMixin().CsrfRetryMixin())) {
      return false;
    }

    await UserModule.SET_USER();

    // Fetch locale from cookie
    // This could come from landing page from netifly
    if (!UserModule.isLoggedIn) {
      const cookieLocale = Vue.$cookies.get('LOCALE');
      if (cookieLocale) {
        localStorage.setItem(LocalStorage.LOCALE, cookieLocale);
      }
      Vue.$cookies.remove('LOCALE');
    }

    // Fetch locale, trasnlations, utils and generics
    await Promise.all([
      TranslationModule.SET_LOCALE(),
      TranslationModule.SET_TRANSLATIONS(),
      UtilModule.SET_DEFAULTS(),
    ]);

    await GenericModule.SET_DEFAULTS();

    // Fetch company data and system with user references, if user is logged in
    if (UserModule.isLoggedIn) {
      await CompanyModule.SET_COMPANY();

      if (CompanyModule.getCompany) {
        await ReferenceModule.SET_REFERENCES();
        await ReferenceModule.SET_USER_REFERENCES();
      }
    }

    return true;
  }

  @Action({ commit: 'UPDATE_IS_READY' })
  public async SWITCH_COMPANY(): Promise<boolean> {
    try {
      await UserModule.SET_USER();

      if (UserModule.hasCompanies) {
        await CompanyModule.SET_COMPANY();
        await ReferenceModule.SET_USER_REFERENCES();

        await new StockMixin().listStocks();
        await new ParterMixin().listPartners();

        UserModule.isAdmin ? await new RecurringMixin().listRecurrings() : await RecurringModule.CLEAR_STATE();

        const currentRoute: Route = app.$router.currentRoute;
        const targetRoute: Location = { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } };

        // Check if the current route is different from the target route
        if (currentRoute.name !== targetRoute.name || !isEqual(currentRoute.params, targetRoute.params)) {
          await app.$router.push(targetRoute);
        } else {
          await new DocumentMixin().listDocuments();
          await new ChartMixin().fetchDocumentChart();
        }
      } else {
        await CompanyModule.CLEAR_COMPANY();
        await ReferenceModule.CLEAR_USER_REFERENCES();
        await StockModule.CLEAR_STATE();
        await PartnerModule.CLEAR_STATE();
        await DocumentModule.CLEAR_STATE();
        await RecurringModule.CLEAR_STATE();

        await app.$router.push({ name: ProfileRouteName.DATA });
      }
    } catch (_) {
      app.$router.go(0);
    }

    return true;
  }

  // Clear application is in maintenance mode
  @Action({ commit: 'UPDATE_IS_MAINTENANCE' })
  public async CLEAR_IS_MAINTENANCE(): Promise<boolean> {
    return false;
  }

  // Mutate application is maintenance state
  @Mutation
  public UPDATE_IS_MAINTENANCE(isMaintenance: boolean): boolean {
    return (this.isMaintenance = isMaintenance);
  }

  // Mutate application is ready state
  @Mutation
  public UPDATE_IS_READY(isReady: boolean): boolean {
    return (this.isReady = isReady);
  }
}

const AppModule = getModule(App);

export default AppModule;
