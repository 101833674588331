import { AxiosPromise } from 'axios';
import Repository from '@/repository/Index';
import type IStockForm from '@/interfaces/forms/stock/IStockForm';
import type IUrlParams from '@/interfaces/IUrlParams';

export default class StockRepository {
  // List all stock
  public static list(companyUUID: string, params: IUrlParams): AxiosPromise {
    return Repository.get('/companies/' + companyUUID + '/predefined-items', { params });
  }

  // Create new stock
  public static create(companyUUID: string, payload: IStockForm): AxiosPromise {
    return Repository.post('/companies/' + companyUUID + '/predefined-items', payload);
  }

  // Update existing stock
  public static update(companyUUID: string, stockUUID: string, payload: IStockForm): AxiosPromise {
    return Repository.put('/companies/' + companyUUID + '/predefined-items/' + stockUUID, payload);
  }

  // Delete existing stock
  public static delete(companyUUID: string, stockUUID: string): AxiosPromise {
    return Repository.delete('/companies/' + companyUUID + '/predefined-items/' + stockUUID);
  }
}
