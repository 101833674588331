enum RecurringRouteName {
  CREATE = 'app.recurring.create',
  COPY = 'app.recurring.copy',
  EDIT = 'app.recurring.edit',
  LIST = 'app.recurring.document',

  INDEX = 'app.recurring.index',
}

export default RecurringRouteName;
