<template>
  <validation-provider
    :rules="rules"
    :debounce="debounceValidation"
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    tag="div"
    class="relative flex flex-col items-start gap-y-2 text-left outline-none"
    :class="{ 'cursor-not-allowed': disabled }"
  >
    <label
      v-if="showLabel && label"
      :for="name"
      class="float-left text-left text-inherit leading-none w-full"
      :class="dark ? 'white--text' : 'black--text'"
    >
      <slot name="label" :label="label">
        <span v-text="label" />
      </slot>
    </label>
    <BaseAutocomplete
      v-model="formValue"
      :field-type="fieldType"
      :input-type="inputType"
      :ignore-input="ignoreInput"
      :postfix="postfix"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :name="name"
      :overflow="overflow"
      :filterable="filterable"
      :filter-description="filterDescription"
      :sortable="sortable"
      :clearable="clearable"
      :autofocus="autofocus"
      :readonly="readonly"
      :loading="loading"
      :disabled="disabled"
      :rounded="rounded"
      :resize="resize"
      :prepare-input="prepareInput"
      :show-error-border="!!error(errors) && showErrorBorder"
      :hide-selected="hideSelected"
      :hide-list="hideList"
      :use-search="useSearch"
      :dark="dark"
      :color="color"
      :input-class="inputClass"
      :text-color="textColor"
      :list-height="listHeight"
      :list-item-height="listItemHeight"
      :font-size="fontSize"
      :list-font-size="listFontSize"
      :items="items"
      :map-item="mapItem"
      @focus="focus"
      @blur="blur"
      @open="open"
      @close="close"
      @query="query"
      @set="set"
      @clear="clear"
      @keydown="keydown"
      @input:debounce="inputDebounce"
      @resize="resizeCompleted"
    >
      <template #prepend-outer>
        <slot name="prepend-outer" />
      </template>
      <template #prepend>
        <slot name="prepend" />
      </template>
      <template #append>
        <slot name="append" />
      </template>
      <template #append-outer>
        <slot name="append-outer" />
      </template>
      <template #list-item="{ item, text }">
        <slot name="list-item" :item="item" :text="text" />
      </template>
    </BaseAutocomplete>
    <v-scroll-y-transition>
      <div v-show="!!error(errors) && showError" class="text-caption error--text mt-n1" v-text="error(errors)" />
    </v-scroll-y-transition>
  </validation-provider>
</template>

<script lang="ts">
  import { Component, Prop, Vue, VModel, Emit } from 'vue-property-decorator';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import type ISelectMap from '@/interfaces/config/ISelectMap';
  import BaseAutocomplete from '@/components/global/inputs/autocomplete/Base.vue';

  @Component({
    components: {
      BaseAutocomplete,
    },
  })
  export default class LabeledBaseAutocomplete extends Vue {
    @VModel({ default: null }) formValue!: string[] | string | number | null;

    @Prop({ default: 'input' }) fieldType!: 'textarea' | 'input';
    @Prop({ default: KeypressInputType.TEXT }) inputType!: KeypressInputType;
    @Prop({ default: '' }) ignoreInput!: string;
    @Prop({ default: '' }) postfix!: string;

    @Prop({ default: 'off' }) autocomplete?: string;
    @Prop({ default: '-' }) placeholder!: string;
    @Prop({ default: '' }) name!: string;
    @Prop({ default: '' }) label!: string;
    @Prop({ default: true }) showLabel!: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules!: string;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) filterable?: boolean;
    @Prop({ type: Boolean }) filterDescription?: boolean;
    @Prop({ type: Boolean }) sortable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;

    @Prop({ type: Boolean }) overflow?: boolean;
    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) resize?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop({ type: Boolean }) hideSelected?: boolean;
    @Prop({ type: Boolean }) hideList?: boolean;
    @Prop({ type: Boolean }) useSearch?: boolean;

    @Prop() prepareInput?: (value: typeof this.formValue) => typeof value;

    @Prop() color?: string;
    @Prop() textColor?: string;
    @Prop() inputClass?: string;

    @Prop({ default: 350 }) listHeight!: number;
    @Prop({ default: 44 }) listItemHeight!: number;
    @Prop({ default: 'text-body-2' }) fontSize!: string;
    @Prop({ default: 'text-body-2' }) listFontSize?: string;

    @Prop() items!: any[] | undefined;
    @Prop() mapItem!: ISelectMap;

    /*****         computed       *****/
    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('focus')
    public focus(): void {}

    @Emit('blur')
    public blur(): void {}

    @Emit('open')
    public open(): void {}

    @Emit('close')
    public close(): void {}

    @Emit('input:debounce')
    public inputDebounce(userInput: string): string {
      return userInput;
    }

    @Emit('query')
    public query(query: string): string {
      return query;
    }

    @Emit('set')
    public set(item: any): any {
      return item;
    }

    @Emit('clear')
    public clear(): void {}

    @Emit('keydown')
    public keydown(keyCode: string): string {
      return keyCode;
    }

    @Emit('resize')
    public resizeCompleted(): void {}

    @Emit('error')
    public error(errors: any): string {
      if (errors.length) {
        return errors[0];
      }
      return '';
    }

    /*****         helpers        *****/
    /*****      vue lifecycle     *****/
  }
</script>
