import { AxiosPromise } from 'axios';
import Repository from '@/repository/Index';
import type ILoginForm from '@/interfaces/forms/auth/ILoginForm';
import type IRegisterProfileForm from '@/interfaces/forms/auth/IRegisterProfileForm';
import type IPasswordForgotForm from '@/interfaces/forms/auth/IPasswordForgotForm';
import type IResetPasswordForm from '@/interfaces/forms/auth/IPasswordResetForm';

export default class AuthRepository {
  // Login user in application
  public static login(payload: ILoginForm): AxiosPromise {
    return Repository.post('/login', payload);
  }

  // Register user in application and send registerion email to user email
  public static register(payload: IRegisterProfileForm): AxiosPromise {
    return Repository.post('/register', payload);
  }

  // Logout the user from application
  public static logout(): AxiosPromise {
    return Repository.post('/logout');
  }

  // User request password reset and receives password reset email in mailbox
  public static requestPasswordReset(payload: IPasswordForgotForm): AxiosPromise {
    return Repository.post('/password/request', payload);
  }

  // Reset user password
  public static resetPassword(payload: IResetPasswordForm): AxiosPromise {
    return Repository.post('/password/reset', payload);
  }

  // Reset user session token
  public static csrfCookie(): AxiosPromise {
    return Repository.get('/sanctum/csrf-cookie');
  }
}
