import app from '@/main';
import store from '@/store';
import isEqual from 'lodash-es/isEqual';
// import cloneDeep from 'lodash-es/cloneDeep';
import useCopyObject from '@/services/CopyObject';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import COINLESS_CURRENCY from '@/constants/config/currency/Coinless';
import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
// import DEFAULT_STOCK_FORM from '@/constants/mocks/forms/Stock';
import LocalStorage from '@/enums/config/LocalStorage';
import DocumentType from '@/enums/config/document/Type';
import CountryISO from '@/enums/config/iso/CountryISO';
import DocumentRouteName from '@/enums/config/router/Document';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type DocumentStatus from '@/enums/config/document/Status';
import type SignatureType from '@/enums/generics/SignatureType';
import type IChart from '@/interfaces/document/IChart';
import type IDocument from '@/interfaces/document/IDocument';
import type IDocumentList from '@/interfaces/document/IDocumentList';
import type IDocumentPermissions from '@/interfaces/document/IDocumentPermissions';
import type IDocumentOriginRecord from '@/interfaces/document/IDocumentOriginRecord';
import type ITotal from '@/interfaces/document/ITotal';
import type IStock from '@/interfaces/stock/IStock';
import CompanyModule from '@/store/modules/Company';
import UtilModule from '@/store/modules/Util';

@Module({
  store,
  name: 'Document',
  namespaced: true,
  dynamic: true,
})
class Document extends VuexModule {
  private document: IDocument = DEFAULT_DOCUMENT_FORM;
  private initDocument: IDocument = DEFAULT_DOCUMENT_FORM;
  private documentTotals: ITotal[] = [];
  private documentList: IDocumentList[] = [];
  private documentChart: IChart[] = [];
  private compactMode = false;

  public get getDocument(): IDocument {
    return this.document;
  }

  public get getInitDocument(): IDocument {
    return this.initDocument;
  }

  public get getDocumentPermissions(): IDocumentPermissions {
    return this.document.permissions;
  }

  public get getDocumentItems(): IStock[] {
    return this.document.items;
  }

  public get getDocumentOriginRecord(): IDocumentOriginRecord | null {
    return this.document.origin_record;
  }

  public get getSignatureType(): SignatureType {
    return this.document.signature.type;
  }

  public get getDocumentType(): DocumentType {
    return this.document.type as DocumentType;
  }

  public get getDocumentCountry(): CountryISO {
    return this.document.sender.country ?? CountryISO.LV;
  }

  public get getDocumentCurrencyCode(): CurrencyISO {
    return this.document.currency_code;
  }

  public get getDocumentCurrencySymbol(): CurrencyISO | string {
    const currencyCode = this.getDocumentCurrencyCode;
    return UtilModule.getCurrencies.find((c) => c.code === currencyCode)?.symbol ?? currencyCode;
  }

  public get getDocumentCurrencyRate(): number {
    return this.document.currency_rate;
  }

  public get getDocumentColor(): string {
    return this.document.color;
  }

  public get getDocumentSingleVat(): number | null {
    return this.document.single_vat ?? null;
  }

  public get getTotals(): ITotal[] {
    return this.documentTotals;
  }

  public get forceUpdateCompany(): boolean {
    return this.document.sender.update_globally ?? false;
  }

  public get isMultiCurrency(): boolean {
    return this.getDocumentCurrencyCode != CompanyModule.getCompanyCurrencyCode;
  }

  public get isTaxPayer(): boolean {
    return !!this.document.sender.vat_registration_number;
  }

  public get isTaxByGroup(): boolean {
    return this.document.tax_by_group;
  }

  public get hasDiscount(): boolean {
    return this.document.has_discount;
  }

  public get hasMultiVat(): boolean {
    return this.document.has_multi_vat;
  }

  public get hasZeroVat(): boolean {
    return this.document.items.some((i) => i.vat_percent === null || Number(i.vat_percent) === 0);
  }

  public get hasAdditionalPriceRows(): boolean {
    return (this.document.additional_price_rows!.length || 0) > 0;
  }

  public get hasDocumentLogs(): boolean {
    return (this.document.logs!.length || 0) > 0;
  }

  public get hasUnsupportedTotalAmount(): boolean {
    return (this.document.total_with_vat ?? 0) >= 1000000000;
  }

  public get isBillable(): boolean {
    return [DocumentType.INVOICE, DocumentType.WAYBILL, DocumentType.CREDIT_NOTE].includes(
      this.document.type as DocumentType,
    );
  }

  public get isAdditionalPriceRows(): boolean {
    return [DocumentType.INVOICE, DocumentType.WAYBILL].includes(this.document.type as DocumentType);
  }

  public get isNewDocument(): boolean {
    return app.$route.name !== DocumentRouteName.EDIT && app.$route.name !== DocumentRouteName.VIEW;
  }

  public get isCopyDocument(): boolean {
    return app.$route.name === DocumentRouteName.COPY;
  }

  public get isDocumentViewOnly(): boolean {
    return app.$route.name === DocumentRouteName.VIEW || !app.isAllowedTo(this.document.permissions).edit;
  }

  public get isSameDocument(): boolean {
    // Generate default stock item and calculate posible vat percent
    const defaultItem = {
      ...app.generateDefaultStock(),
      vat_percent: this.document.sender.vat_registration_number
        ? this.document.has_multi_vat
          ? CompanyModule.getDefaultTaxRate
          : this.document.single_vat
        : null,
    };

    // Create a copy of the document and init document
    // Remove empty items with array filter
    // And clean item values with array map
    // To prepare them for comparison
    const document = JSON.parse(
      JSON.stringify({
        ...this.document,
        items: this.document.items
          .filter((i: IStock) => {
            const item = app.generateDefaultStock(i);
            return !isEqual(item, defaultItem);
          })
          .map((i: IStock) => {
            return app.generateDefaultStock(i);
          }),
        currency_rate: app.toNumber(this.document.currency_rate),
        logs: [],
      }),
    );

    const initDocument = JSON.parse(
      JSON.stringify({
        ...this.initDocument,
        items: this.initDocument.items
          .filter((i: IStock) => {
            const item = app.generateDefaultStock(i);
            return !isEqual(item, defaultItem);
          })
          .map((i: IStock) => {
            return app.generateDefaultStock(i);
          }),
        currency_rate: app.toNumber(this.initDocument.currency_rate),
        logs: [],
      }),
    );

    // Compare document and init document
    return isEqual(document, initDocument);
  }

  // Document list and chart getters
  public get getDocumentList(): IDocumentList[] {
    return this.documentList;
  }

  public get getDocumentChart(): IChart[] {
    return this.documentChart;
  }

  public get getDocumentChartTotalAmount(): number {
    return this.documentChart.reduce((total, chart) => total + chart.total_with_vat, 0);
  }

  public get showDocumentChart(): boolean {
    return this.documentChart.length > 2;
  }

  // Document list compact mode
  public get isCompactMode(): boolean {
    return this.compactMode;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_DOCUMENT' })
  public async SET_DOCUMENT(document: Partial<IDocument>): Promise<IDocument> {
    const mergedDocument: IDocument = useCopyObject().copy(document, this.document);
    return mergedDocument;
  }

  @Action({ commit: 'UPDATE_INIT_DOCUMENT' })
  public async SET_INIT_DOCUMENT(document: Partial<IDocument>): Promise<IDocument> {
    const mergedDocument: IDocument = useCopyObject().copy(document, this.document);
    return JSON.parse(JSON.stringify(mergedDocument));
  }

  @Action({ commit: 'UPDATE_DOCUMENT_TOTALS' })
  public async SET_DOCUMENT_TOTALS(items = this.getDocumentItems): Promise<ITotal[]> {
    const documentTotals: ITotal[] = items.reduce((acc: ITotal[], item) => {
      const vatRate = item.vat_percent === null ? null : app.toNumber(item.vat_percent);
      const totalWithoutVat = app.toNumber(item.total_without_vat);
      const totalWithVat = app.toNumber(item.total_with_vat);
      const totalVat = totalWithVat - totalWithoutVat;

      let total = acc.find((total) => total.vat_rate === vatRate);

      if (!total) {
        total = {
          vat_rate: vatRate,
          total_without_vat: 0,
          total_with_vat: 0,
          total_vat: 0,
        };
        acc.push(total);
      }

      total.total_vat += totalVat;
      total.total_without_vat += totalWithoutVat;
      total.total_with_vat += totalWithVat;

      return acc;
    }, []);

    // Calculate totals for the document based on vat calculation method
    documentTotals.forEach((total) => {
      if (this.isTaxByGroup) {
        const vatRate = total.vat_rate;
        const totalWithoutVat = total.total_without_vat;

        total.total_with_vat = app.roundUp(app.floorDown(totalWithoutVat * ((vatRate ?? 0) / 100 + 1), 3));
        total.total_vat = total.total_with_vat - totalWithoutVat;
      }

      total.total_without_vat = app.roundUp(app.floorDown(total.total_without_vat), 4);
      total.total_with_vat = app.roundUp(app.floorDown(total.total_with_vat), 4);
      total.total_vat = app.roundUp(app.floorDown(total.total_vat), 4);
    });

    return documentTotals;
  }

  @Action({ commit: 'UPDATE_DOCUMENT_LIST' })
  public async SET_DOCUMENT_LIST(documentList: IDocumentList[]): Promise<IDocumentList[]> {
    return documentList;
  }

  @Action({ commit: 'UPDATE_DOCUMENT_LIST' })
  public async SET_DOCUMENT_STATUS(payload: {
    documentUUIDs: string[];
    status: DocumentStatus;
  }): Promise<IDocumentList[]> {
    const { documentUUIDs, status } = payload;
    const documentUUIDsSet = new Set(documentUUIDs);

    return this.documentList.map((document) => {
      if (documentUUIDsSet.has(document.uuid)) {
        document.status = status;
      }
      return document;
    });
  }

  @Action({ commit: 'UPDATE_DOCUMENT_LIST' })
  public async SET_DOCUMENT_PAYMENT(payload: { documentUUID: string; paidAmount: number }): Promise<IDocumentList[]> {
    const { documentUUID, paidAmount } = payload;

    return this.documentList.map((document) => {
      if (documentUUID === document.uuid) {
        document.total_payment_amount = paidAmount;
        document.total_payment_amount_local = paidAmount * this.document.currency_rate;
      }
      return document;
    });
  }

  @Action({ commit: 'UPDATE_DOCUMENT_CHART' })
  public async SET_DOCUMENT_CHART(documentChart: IChart[]): Promise<IChart[]> {
    return documentChart;
  }

  @Action({ commit: 'UPDATE_COMPACT_MODE' })
  public async SET_COMPACT_MODE(): Promise<boolean> {
    return localStorage.getItem(LocalStorage.COMPACT_MODE) === 'on';
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_DOCUMENT' })
  public async CLEAR_DOCUMENT(): Promise<IDocument> {
    await this.CLEAR_INIT_DOCUMENT();
    return DEFAULT_DOCUMENT_FORM;
  }

  @Action({ commit: 'UPDATE_INIT_DOCUMENT' })
  public async CLEAR_INIT_DOCUMENT(): Promise<IDocument> {
    return DEFAULT_DOCUMENT_FORM;
  }

  @Action({ commit: 'UPDATE_DOCUMENT_TOTALS' })
  public async CLEAR_DOCUMENT_TOTALS(): Promise<ITotal[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_DOCUMENT_LIST' })
  public async CLEAR_PAGINATION(): Promise<IDocumentList[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_DOCUMENT_CHART' })
  public async CLEAR_DOCUMENT_CHART(): Promise<IChart[]> {
    return [];
  }

  @Action
  public async CLEAR_STATE(): Promise<void> {
    await this.CLEAR_DOCUMENT();
    await this.CLEAR_DOCUMENT_TOTALS();
    await this.CLEAR_PAGINATION();
    await this.CLEAR_DOCUMENT_CHART();
  }

  @Action({ commit: 'UPDATE_COMPACT_MODE' })
  public async CLEAR_COMPACT_MODE(): Promise<boolean> {
    return false;
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_DOCUMENT(document: IDocument): Promise<IDocument> {
    return (this.document = document);
  }

  @Mutation
  public async UPDATE_INIT_DOCUMENT(document: IDocument): Promise<IDocument> {
    return (this.initDocument = document);
  }

  @Mutation
  public async UPDATE_DOCUMENT_TOTALS(documentTotals: ITotal[]): Promise<ITotal[]> {
    // Update document totals in document currency
    const isCoinless = COINLESS_CURRENCY.includes(this.document.currency_code);

    // Calculate additional price rows total based on the document type
    const isAdditionalPriceRows = [DocumentType.INVOICE, DocumentType.WAYBILL].includes(
      this.document.type as DocumentType,
    );
    const totalAdditional = isAdditionalPriceRows
      ? this.document.additional_price_rows.reduce((total, row) => total + app.toNumber(row.amount), 0)
      : 0;

    const totalWithoutVat = documentTotals.reduce((total, item) => total + item.total_without_vat, 0);
    const totalWithVat = documentTotals.reduce((total, item) => total + item.total_with_vat, 0);

    this.document.total_without_vat = app.roundUp(totalWithoutVat, isCoinless ? 0 : 3);
    this.document.total_with_vat = app.roundUp(totalWithVat - totalAdditional, isCoinless ? 0 : 3);
    this.document.vat_amount = app.roundUp(totalWithVat - totalWithoutVat, isCoinless ? 0 : 3);

    // Update document totals in local currency
    const isLocalCoinless = COINLESS_CURRENCY.includes(CompanyModule.getCompanyCurrencyCode);

    const totalAdditionalLocal = totalAdditional * this.document.currency_rate;
    const totalWithoutVatLocal = totalWithoutVat * this.document.currency_rate;
    const totalWithVatLocal = totalWithVat * this.document.currency_rate;

    this.document.total_without_vat_local = app.roundUp(totalWithoutVatLocal, isLocalCoinless ? 0 : 3);
    this.document.total_with_vat_local = app.roundUp(totalWithVatLocal - totalAdditionalLocal, isLocalCoinless ? 0 : 3);
    this.document.vat_amount_local = app.roundUp(totalWithVatLocal - totalWithoutVatLocal, isLocalCoinless ? 0 : 3);

    return (this.documentTotals = documentTotals);
  }

  @Mutation
  public async UPDATE_DOCUMENT_LIST(documentList: IDocumentList[]): Promise<IDocumentList[]> {
    return (this.documentList = documentList);
  }

  @Mutation
  public async UPDATE_DOCUMENT_CHART(documentChart: IChart[]): Promise<IChart[]> {
    return (this.documentChart = documentChart);
  }

  @Mutation
  public async UPDATE_COMPACT_MODE(compactMode: boolean): Promise<boolean> {
    return (this.compactMode = compactMode);
  }
}

const DocumentModule = getModule(Document);

export default DocumentModule;
