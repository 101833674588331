import axios, { AxiosRequestConfig } from 'axios';
import ErrorModule from '@/store/modules/Error';
import TranslationModule from '@/store/modules/Translation';

export const apiBaseURL = process.env.VUE_APP_API_URL;

export const axiosConfig = {
  baseURL: apiBaseURL,
  withCredentials: true,
};

const Repository = axios.create(axiosConfig);

// Setup interceptors
Repository.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    await TranslationModule.SET_LOCALE();
    config.headers['App-Locale'] = TranslationModule.getLocale;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

Repository.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    await ErrorModule.SET_INTERCEPTOR_ERROR(error);
    return Promise.reject(error);
  },
);

// Export
export default Repository;
