import { Component, Vue } from 'vue-property-decorator';
import JobStatus from '@/enums/generics/JobStatus';
import TimeConfig from '@/enums/config/TimeConfig';
import AppState from '@/enums/generics/AppState';
import type IDocumentList from '@/interfaces/document/IDocumentList';
import type IBulkDownloadResponse from '@/interfaces/document/response/IBulkDownloadResponse';
import { apiBaseURL } from '@/repository/Index';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class BulkDownloadDocumentsMixin extends Vue {
  public async BulkDownloadDocumentsMixin(
    documents: IDocumentList[],
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const documentUUIDs: string[] = documents.map((document) => document.uuid);
    const isSuccess = (await DocumentRepository.bulkDownload(companyUUID, documentUUIDs)).data.success;
    if (isSuccess) {
      await this.BulkDownloadDocumentsProgressMixin(companyUUID);
    }
  }

  public async BulkDownloadDocumentsProgressMixin(companyUUID: string = CompanyModule.getCompanyUUID) {
    let tryToRetry: number = TimeConfig.MAX_RETRIES;

    while (tryToRetry) {
      try {
        const job: IBulkDownloadResponse = (await DocumentRepository.bulkDownloadProgress(companyUUID)).data;

        if (job) {
          if (job.stage === JobStatus.FAILED) {
            await this.$notifier({
              message: this.trans('notify.error.internal', 'Aplikācijas darbības kļuda'),
              state: AppState.ERROR,
            });
            return;
          }

          if (job.stage === JobStatus.COMPLETED && job.has_file && job.is_completed) {
            this.BulkDownloadDocumentsZipFileMixin();
            return;
          }
        }
      } catch (e) {
        if (tryToRetry === 1) {
          this.$logger('Bulk document download max retries reached');
          await this.$notifier({
            message: this.trans('notify.error.download_terminated.zip', 'ZIP faila lejupielāde ir pārtraukta'),
            state: AppState.ERROR,
          });
        }
      }

      tryToRetry--;
      await new Promise((resolve) => setTimeout(resolve, TimeConfig.INTERVAL));
    }
  }

  public BulkDownloadDocumentsZipFileMixin(companyUUID: string = CompanyModule.getCompanyUUID) {
    const title = this.trans('menu.documents', 'Dokumenti')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    const zipFileName: string = `${title}.zip`;
    const url: string = `${apiBaseURL}/companies/${companyUUID}/bookkeeping-records/bulk/download-resulting-file`;

    // Create a new anchor element and set the download attribute to the document name
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url.toString();
    link.download = zipFileName;
    link.style.display = 'none';

    // Append the anchor element to the document body and click it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
