import { AxiosPromise } from 'axios';
import Repository from '@/repository/Index';
import useFormBuilder from '@/services/FormBuilder';
import type ISupportForm from '@/interfaces/forms/ISupportForm';

const { jsonToFormData } = useFormBuilder();

export default class SupportRepository {
  // User support form
  public static contact(payload: ISupportForm): AxiosPromise {
    return Repository.post('/contact-form/submit', jsonToFormData(payload));
  }
}
