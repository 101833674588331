import { AxiosPromise } from 'axios';
import Repository from '@/repository/Index';
import type IReferenceForm from '@/interfaces/forms/IReferenceForm';

export default class ReferencesRepository {
  // Fetch VAT references
  public static list(companyUUID: string): AxiosPromise {
    return Repository.get(`/companies/${companyUUID}/vat-references`);
  }

  // Create VAT reference
  public static create(companyUUID: string, payload: IReferenceForm): AxiosPromise {
    return Repository.post(`/companies/${companyUUID}/vat-references`, payload);
  }

  // Update VAT reference
  public static update(companyUUID: string, referenceUUID: string, payload: IReferenceForm): AxiosPromise {
    return Repository.put(`/companies/${companyUUID}/vat-references/${referenceUUID}`, payload);
  }

  // Delete VAT reference
  public static delete(companyUUID: string, referenceUUID: string): AxiosPromise {
    return Repository.delete(`/companies/${companyUUID}/vat-references/${referenceUUID}`);
  }

  // Set default VAT reference
  public static setDefault(companyUUID: string, referenceUUID: string): AxiosPromise {
    return Repository.post(`/companies/${companyUUID}/vat-references/${referenceUUID}/set-default`);
  }

  // Fetch app defined VAT references
  public static listAppDefined(): AxiosPromise {
    return Repository.get('/app-data/vat-references');
  }
}
