import app from '@/main';
import store from '@/store';
import keyBy from 'lodash-es/keyBy';
import { localeChanged } from 'vee-validate';
import usePrototype from '@/services/Prototype';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import TranslationsRepository from '@/repository/Translations';
import Locale from '@/enums/config/Locale';
import LocalStorage from '@/enums/config/LocalStorage';
import type ITranslation from '@/interfaces/ITranslation';
import LoadingModule from '@/store/modules/Loading';
import GenericModule from '@/store/modules/Generic';
import UserModule from '@/store/modules/User';
import UtilModule from '@/store/modules/Util';

@Module({
  store,
  name: 'Translation',
  namespaced: true,
  dynamic: true,
})
class Translation extends VuexModule {
  private locale: Locale = '' as Locale;
  private translations: ITranslation[] = [];

  /*******   Locale   *******/

  public get getLocale(): Locale {
    return this.locale;
  }

  /*******   Translations   *******/

  public get getLocales(): Locale[] {
    return this.getTranslations.map((e: ITranslation) => e.locale);
  }

  public get getTranslations(): ITranslation[] {
    return this.translations;
  }

  public get getKeyedTranslations(): Record<string, ITranslation> {
    return keyBy(this.getTranslations, 'locale');
  }

  public get getLocaleTranslation(): ITranslation | undefined {
    return this.getTranslations.find((t: ITranslation): boolean => {
      return t.locale === this.getLocale;
    });
  }

  public get getKeyTranslation(): (key: string, locale?: Locale) => string {
    return (key: string, locale?: Locale): string => {
      return this.getKeyedTranslations[locale ?? this.getLocale].translations[key] ?? '';
    };
  }

  /*******   Validation messages   *******/

  public get getValidatorDictionary(): Record<string, string> {
    if (this.getLocaleTranslation) {
      return TranslationsRepository.validatorDictionary(this.getLocaleTranslation.translations);
    }

    return {};
  }

  public get getValidationKeyTranslation(): (field: string, params: Record<string, any>) => string {
    return (field: string, params: Record<string, any>): string => {
      const { hasOwnProperty } = usePrototype();
      if (hasOwnProperty(this.getValidatorDictionary, params._rule_)) {
        let translation = this.getValidatorDictionary[params._rule_];

        Object.keys(params).forEach((key: string) => {
          translation = translation.replace(`{${key}}`, params[key]);
        });

        return translation;
      }

      return 'No error message defined!';
    };
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_LOCALE' })
  public async SET_LOCALE(): Promise<Locale> {
    const userLocale = UserModule.getUserLocale;
    const storageLocale = localStorage.getItem(LocalStorage.LOCALE) as Locale;

    const translation = this.translations.find((t: ITranslation) => t.is_fallback);
    const fallbackLocale = translation ? translation.locale : Locale.LV;

    // Verify if locale is valid
    const localeToSet: Locale = userLocale || storageLocale || fallbackLocale;
    const locale: Locale = Object.values(Locale).includes(localeToSet) ? localeToSet : fallbackLocale;

    // Set locale
    await UserModule.SET_USER_FIELD({ locale: locale });
    localStorage.setItem(LocalStorage.LOCALE, locale);

    // Set HTML lang attribute
    document.documentElement.lang = locale;

    return locale;
  }

  @Action
  public async SWITCH_LOCALE(locale: Locale) {
    await LoadingModule.SET_LOADING_LOCALE();

    await UserModule.SET_USER_FIELD({ locale: locale });
    localStorage.setItem(LocalStorage.LOCALE, locale);
    this.SET_LOCALE();
    await this.ADD_TRANSLATIONS(locale);

    // Change translations to other state variables
    await UtilModule.SET_DEFAULTS();
    await GenericModule.SET_DEFAULTS();

    localeChanged();

    await LoadingModule.CLEAR_LOADING_LOCALE();
  }

  @Action({ commit: 'UPDATE_TRANSLATIONS' })
  public async SET_TRANSLATIONS(): Promise<ITranslation[]> {
    let translations: ITranslation[] = [];
    try {
      translations = (await TranslationsRepository.fetchTranslations()).data;
    } catch (e) {
      app.$logger('unable to fetch translation...');
    }
    return translations;
  }

  @Action({ commit: 'UPDATE_TRANSLATIONS' })
  public async ADD_TRANSLATIONS(locale: Locale): Promise<ITranslation[]> {
    const translations: ITranslation[] = this.getTranslations;

    locale = Object.values(Locale).includes(locale) ? locale : this.getLocale;
    const translationExists: boolean = !!Object.entries(this.getKeyedTranslations[locale].translations).length;
    if (locale && !translationExists) {
      let translation: ITranslation[] = [];

      try {
        translation = (await TranslationsRepository.fetchTranslations(locale)).data;
      } catch (e) {
        app.$logger('unable to fetch translation...');
      }

      if (translation.length) {
        translations.find((e: ITranslation) => {
          if (e.locale === locale) {
            e.translations = keyBy(translation, 'locale')[locale].translations;
          }
        });
      }
    }

    return translations;
  }

  /*******   Update mutation   *******/

  @Mutation
  public UPDATE_LOCALE(locale: Locale): Locale {
    return (this.locale = locale);
  }

  @Mutation
  public UPDATE_TRANSLATIONS(translations: ITranslation[]): ITranslation[] {
    return (this.translations = translations);
  }
}

const TranslationModule = getModule(Translation);

export default TranslationModule;
