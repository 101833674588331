import Repository from '@/repository/Index';
import { AxiosPromise } from 'axios';
import type IProfileUpdateForm from '@/interfaces/forms/IProfileUpdateForm';
import type IVerificationForm from '@/interfaces/forms/auth/IVerificationForm';

export default class UserRepository {
  // Fetch user profile data
  public static fetch(): AxiosPromise {
    return Repository.get('/user');
  }

  // Update user profile data
  public static update(payload: IProfileUpdateForm): AxiosPromise {
    return Repository.put('/user', payload);
  }

  // Switch user company
  public static switchCompany(companyUUID: string, remember_company: boolean): AxiosPromise {
    return Repository.post(`/user/switch-company/${companyUUID}`, { remember_company });
  }

  // Leave user company
  public static leaveCompany(companyUUID: string): AxiosPromise {
    return Repository.post(`/user/companies/${companyUUID}/leave`);
  }

  // Resend verification email
  public static resendEmailVerification(): AxiosPromise {
    return Repository.post('/email/resend');
  }

  // Verify users new email address
  public static verifyEmail(payload: IVerificationForm): AxiosPromise {
    return Repository.post('/email/verify', null, { params: payload });
  }

  // Close users account
  public static close(password: string): AxiosPromise {
    return Repository.post('/user/close-account', { password });
  }

  // Fetch user invitation data
  public static fetchInvitation(invitationUUID: string, hash: string): AxiosPromise {
    return Repository.get(`/invitations/${invitationUUID}`, { params: { hash } });
  }

  // Accept user invitation
  public static acceptInvitation(invitationUUID: string, hash: string): AxiosPromise {
    return Repository.post(`/invitations/${invitationUUID}/accept`, null, { params: { hash } });
  }

  // Reject user invitation
  public static rejectInvitation(invitationUUID: string, hash: string): AxiosPromise {
    return Repository.post(`/invitations/${invitationUUID}/reject`, null, { params: { hash } });
  }
}
