import _Vue from 'vue';
import sortBy from 'lodash-es/sortBy';
import isEqual from 'lodash-es/isEqual';

export default function ArrayPlugin(Vue: typeof _Vue): void {
  Vue.prototype.isEqualWhenSorted = (a: any[], b: any[]): boolean => {
    return isEqual(sortBy(a), sortBy(b));
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    isEqualWhenSorted(a: any[], b: any[]): boolean;
  }
}
