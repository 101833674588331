import Vue from 'vue';
import Router, { Route } from 'vue-router';
import useLocalStorage from '@/services/LocalStorage';
import AuthIndexRouter from '@/router/auth/Index';
import AppIndexRouter from '@/router/app/Index';
import PublicIndexRouter from '@/router/public/Index';

import routerGuards from '@/router/Guards';
import AppModule from '@/store/modules/App';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...AuthIndexRouter, ...AppIndexRouter, ...PublicIndexRouter],
});

// on application reload fetch and validate user data
router.beforeEach(async (to: Route, from: Route, next: Function) => {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });

  await AppModule.SET_IS_READY();

  // Unset invitation hash if expired
  useLocalStorage().unsetInvitationHashIfExpired();

  // Check router guards
  await routerGuards.init(to, next);
});

export default router;
