import Repository from '@/repository/Index';
import { AxiosPromise } from 'axios';
import type IPartnerForm from '@/interfaces/forms/partner/IPartnerForm';
import type IUrlParams from '@/interfaces/IUrlParams';
import type IPartnerLookupParams from '@/interfaces/forms/partner/IPartnerLookupParams';

export default class PartnerRepository {
  // List all partners
  public static list(companyUUID: string, params: IUrlParams): AxiosPromise {
    return Repository.get('/companies/' + companyUUID + '/partners', { params });
  }

  // List combined data from partner and business registry database
  public static listMultiSource(companyUUID: string, params: IPartnerLookupParams): AxiosPromise {
    return Repository.get('/companies/' + companyUUID + '/partners/suggestions', { params });
  }

  // Fetch single partner
  public static fetch(companyUUID: string, partnerUUID: string): AxiosPromise {
    return Repository.get('/companies/' + companyUUID + '/partners/' + partnerUUID);
  }

  // Create new partner
  public static create(companyUUID: string, payload: IPartnerForm): AxiosPromise {
    return Repository.post('/companies/' + companyUUID + '/partners', payload);
  }

  // Update existing partner
  public static update(companyUUID: string, partnerUUID: string, payload: IPartnerForm): AxiosPromise {
    return Repository.put('/companies/' + companyUUID + '/partners/' + partnerUUID, payload);
  }

  // Delete existing partner
  public static delete(companyUUID: string, partnerUUID: string): AxiosPromise {
    return Repository.delete('/companies/' + companyUUID + '/partners/' + partnerUUID);
  }
}
