import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import ItemsCell from '@/enums/config/document/ItemsCell';
import Integer from '@/enums/config/Integer';
import type Unit from '@/enums/generics/Unit';
import type IStock from '@/interfaces/stock/IStock';
import type ITotal from '@/interfaces/document/ITotal';
import TranslateMixin from '@/mixins/Translate';

@Component
export default class TotalStockMixin extends Vue {
  // Compose correct document item measurement
  public composeMeasurement(input: Unit | string | null): string {
    const translate = new TranslateMixin();
    return translate.translateUnit(input ?? '');
  }

  // Compose correct document item quatity
  public composeQuantity(input: string | number | null): number {
    const quantity = this.stripFloat(input, Integer.COULD_BE_NEGATIVE);
    return this.floorDown(quantity, 5) ?? 1;
  }

  // Compose correct document item discount
  public composeDiscount(input: string | number | null): number | null {
    const discount = this.floorDown(this.stripFloat(input), 5);
    return discount && discount > 100 ? 100 : discount;
  }

  // Compose correct document item vat percent
  public composeVatPercent(input: string | number | null): number | null {
    const vatPercent = this.stripInteger(input);
    return vatPercent && vatPercent > 100 ? 100 : vatPercent;
  }

  // Compose correct document item price with VAT
  // Numbers should be at least and not more than with 2 decimal places
  public composePriceWithVat(input: string | number | null): number {
    const priceWithVat: number = this.stripFloat(input, Integer.COULD_BE_NEGATIVE) ?? 0;
    return this.setDecimals(this.roundUp(this.floorDown(priceWithVat, 4), 2) ?? 0);
  }

  // Compose correct document item total with VAT
  // Numbers should be at least and not more than with 2 decimal places
  public composeItemTotal(value: string | number | null): number {
    const totalWithVat = this.stripFloat(value, Integer.COULD_BE_NEGATIVE) ?? 0;
    return this.setDecimals(totalWithVat);
  }

  // Compose correct document item price without VAT
  // Number should be at least with 2 decimal places
  public composePrice(stock: IStock, calculateby = ItemsCell.NAME): number {
    if (calculateby === ItemsCell.PRICE_WITH_VAT) {
      const priceWithVat = this.composePriceWithVat(stock.price_with_vat);
      const vatPercent = this.composeVatPercent(stock.vat_percent) ?? 0;

      const price = this.floorDown(priceWithVat / (vatPercent / 100 + 1), 4);
      return this.setDecimals(price ?? 0);
    }

    if (calculateby === ItemsCell.TOTAL_WITH_VAT) {
      const totalWithVat = this.toNumber(stock.total_with_vat ?? 0);
      const quantity = this.composeQuantity(stock.quantity);
      const vatPercent = this.composeVatPercent(stock.vat_percent) ?? 0;
      const discount = this.composeDiscount(stock.discount) ?? 0;

      const price = this.floorDown(totalWithVat / quantity / (vatPercent / 100 + 1) / (1 - discount / 100), 4);
      return this.setDecimals(price ?? 0);
    }

    const price = this.stripFloat(stock.price, Integer.COULD_BE_NEGATIVE) ?? 0;
    return this.setDecimals(this.floorDown(price, 5) ?? 0);
  }

  public composeTotal(stock: IStock): ITotal {
    const quantity = this.composeQuantity(stock.quantity);
    const discount = this.composeDiscount(stock.discount) ?? 0;
    const vat_rate = this.composeVatPercent(stock.vat_percent) ?? 0;

    const price = this.composePrice(stock);

    // Calculate item base price with quantity and discount
    const base: number = price * quantity * ((100 - discount) / 100)!;
    // Calculate item neto and bruto and based on those values calculate vat amount
    const total_without_vat: number = this.roundUp(base)!;
    const total_with_vat: number = this.roundUp(this.floorDown(base * (vat_rate / 100 + 1), 3))!;
    const total_vat: number = this.roundUp(total_with_vat - total_without_vat)!;

    return { vat_rate, total_vat, total_with_vat, total_without_vat };
  }

  public composeItem(stock: IStock): IStock {
    const item = cloneDeep(stock);

    item.quantity = this.composeQuantity(stock.quantity);
    item.discount = this.composeDiscount(stock.discount);

    item.vat_percent = this.composeVatPercent(item.vat_percent);

    item.price = this.composePrice(stock);

    const priceWithVat = Number(stock.price) * (Number(stock.vat_percent ?? 0) / 100 + 1);
    item.price_with_vat = this.composePriceWithVat(priceWithVat);

    const total: ITotal = this.composeTotal(stock);
    item.total_without_vat = this.composeItemTotal(total.total_without_vat);
    item.total_with_vat = this.composeItemTotal(total.total_with_vat);

    return item;
  }

  // Compose correct percent value
  public composePercent(input: string | number | null): number {
    const percent = this.stripFloat(input);
    return this.setDecimals(percent && percent > 100 ? 100 : percent ?? 0);
  }

  // Compose correct days in year value
  public composeDays(input: string | number | null): number | null {
    const daysInYear = this.stripInteger(input);
    return daysInYear && daysInYear > 365 ? 365 : daysInYear;
  }

  // Compose correct prepaid amount value
  public composePrepaidAmount(total?: number, percent?: number): number {
    if (!total || !percent) {
      return this.setDecimals(0);
    }

    const amount = this.roundUp(total * (percent / 100));
    return this.setDecimals(amount ?? 0);
  }

  // Compose correct prepaid percent value
  public composePrepaidPercent(total?: number, amount?: number): number {
    if (!total || !amount) {
      return this.setDecimals(0);
    }

    const percent = this.roundUp((amount / total) * 100);
    return this.setDecimals(percent ?? 0);
  }
}
