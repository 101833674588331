import useEnv from '@/services/Env';
import { Component, Mixins } from 'vue-property-decorator';
import NotificationType from '@/enums/config/NotificationType';
import RegistrySearchField from '@/enums/config/RegistrySearchField';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type ICheckVatStatusForm from '@/interfaces/forms/helper/ICheckVatStatusForm';
import type IUser from '@/interfaces/user/IUser';
import type IVatStatus from '@/interfaces/IVatStatus';
import type IExchangeRate from '@/interfaces/IExchangeRate';
import type IBusinessRegistry from '@/interfaces/IBusinessRegistry';
import type IBankIban from '@/interfaces/bank/IBankIban';
import type IHelperMixin from '@/interfaces/mixins/IHelperMixin';
import NotificationModule from '@/store/modules/Notification';
import CheckVatStatusMixin from '@/mixins/helper/CheckVatStatus';
import FetchCurrencyExchangeRateMixin from '@/mixins/helper/FetchCurrencyExchangeRate';
import FetchIbanMixin from '@/mixins/helper/FetchIban';
import ListBusinessRegistryMixin from '@/mixins/helper/ListBusinessRegistry';
import ListNotificationsMixin from '@/mixins/helper/ListNotifications';
import MarkNotificationsAsReadMixin from '@/mixins/helper/MarkNotificationsAsRead';

const { isDev } = useEnv();

@Component
export default class HelperMixin extends Mixins<IHelperMixin>(
  CheckVatStatusMixin,
  FetchCurrencyExchangeRateMixin,
  FetchIbanMixin,
  ListBusinessRegistryMixin,
  ListNotificationsMixin,
  MarkNotificationsAsReadMixin,
) {
  // Check vat payer/vat registration number status - use CheckVatStatusMixin
  public async checkVatStatus(payload: ICheckVatStatusForm): Promise<IVatStatus> {
    return await this.CheckVatStatusMixin(payload);
  }

  // Fetch currecny exchnage rate (europe central bank - ECB) - use FetchCurrencyExchangeRateMixin
  public async fetchCurrencyExchangeRate(
    currencyFrom: CurrencyISO,
    currencyTo: CurrencyISO,
    exchangeDate?: string,
  ): Promise<IExchangeRate> {
    return await this.FetchCurrencyExchangeRateMixin(currencyFrom, currencyTo, exchangeDate);
  }

  // Find IBAN details from bank account number - use FetchIbanMixin
  public async fetchIban(IBANnumber: string): Promise<IBankIban | null> {
    return await this.FetchIbanMixin(IBANnumber);
  }

  // Search company in business registry database - use ListBusinessRegistryMixin
  public async listBusinessRegistry(query: string, field = RegistrySearchField.NAME): Promise<IBusinessRegistry[]> {
    return await this.ListBusinessRegistryMixin({ query, field });
  }

  // Fetch user notifications (firebase RTDB) - use ListNotificationsMixin
  public async listNotifications(user: IUser): Promise<void> {
    try {
      const notifications = (await this.ListNotificationsMixin(user.email, user.has_verified_email)) ?? [];
      await NotificationModule.SET_NOTIFICATIONS(notifications);
    } catch (_) {
      this.$logger('There was a problem to list notifications...');
      await NotificationModule.CLEAR_NOTIFICATIONS();
    }

    // Use this only for development purposes
    if (isDev) {
      await NotificationModule.SET_NOTIFICATIONS([
        {
          uuid: '236ebd05-c148-4cc5-b86d-6995cbd17a71',
          type: NotificationType.VERIFY_EMAIL,

          title_key: 'system.advanced_invoice_naming_change.titles',
          title_default: 'Avansa rēķina angļu valodas tulkojuma maiņa Avansa rēķina angļu valodas tulkojuma maiņa',

          content_key: 'system.advanced_invoice_naming_change.content',
          content_default: 'Esam nomainījuši angļu valodas tulkojumu avansa rēķins (advance invoice) uz Pro forma',

          expiry: '2023-05-14',
          has_read: true,
        },
        {
          uuid: '236ebd05-c148-4cc5-b86d-6995cbd17a72',
          type: NotificationType.DEFAULT,

          title_key: 'system.advanced_invoice_naming_change.title',
          title_default: 'Avansa rēķina angļu valodas tulkojuma maiņa',

          content_key: 'system.advanced_invoice_naming_change.content',
          content_default: 'Esam nomainījuši angļu valodas tulkojumu avansa rēķins (advance invoice) uz Pro forma',

          expiry: '2023-12-20',
          has_read: false,
        },
        {
          uuid: '236ebd05-c148-4cc5-b86d-6995cbd17a73',
          type: NotificationType.ALERT,

          title_key: 'system.advanced_invoice_naming_change.title',
          title_default: 'Avansa rēķina angļu valodas tulkojuma maiņa',

          content_key: 'system.advanced_invoice_naming_change.content',
          content_default: 'Esam nomainījuši angļu valodas tulkojumu avansa rēķins (advance invoice) uz Pro forma',

          expiry: '2023-12-10',
          has_read: false,
        },
      ]);
    }
  }

  // Update user notifications with has read status (firebase rtdb) - use MarkNotificationsAsReadMixin
  public async markNotificationsAsRead(user: IUser): Promise<void> {
    await this.MarkNotificationsAsReadMixin(user.email, user.has_verified_email);
    await NotificationModule.MARK_NOTIFICATIONS_AS_READ();
  }
}
