var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block space-y-2"},[_c('TextField',{attrs:{"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"clearable":_vm.clearable,"autofocus":_vm.autofocus,"show-label":_vm.showLabel,"show-error":_vm.showError,"show-error-border":_vm.showErrorBorder,"text-color":_vm.textColor,"color":_vm.color,"label":_vm.label,"dark":_vm.dark,"name":_vm.name,"hint":_vm.hint,"rules":_vm.rules,"debounce-validation":_vm.debounceValidation,"readonly":_vm.readonly,"disabled":_vm.disabled,"loading":_vm.isLoading,"rounded":_vm.rounded,"reverse":_vm.reverse,"input-class":_vm.inputClass},on:{"blur":_vm.blur,"focus":_vm.focus,"clear":_vm.clear,"input:debounce":_vm.inputDebounce,"error":_vm.error,"change":_vm.change,"keydown":_vm.keydown,"keypress":_vm.keypress},scopedSlots:_vm._u([{key:"label",fn:function({ label }){return [_c('div',{staticClass:"flex items-center jsutfiy-start gap-2"},[_vm._t("label",function(){return [_c('span',{domProps:{"textContent":_vm._s(label)}})]},{"label":label}),_c('Tooltip',{attrs:{"arrow":"","max-width":220,"placement":_vm.Placement.RIGHT,"interactive":""},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.sanitizeHTML(
                  _vm.trans(
                    'tooltip.vat_check_by_api',
                    'PVN maksātāja statusa pārbaudi veic <a target=\'_blank\' href=\'https://ec.europa.eu/taxation_customs/vies/\'>PVN informācijas apmaiņas sistēma</a>',
                  ),
                )
              )}})]},proxy:true}],null,true)})],2)]}},{key:"append",fn:function(){return [_c('v-scale-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[_c('Tooltip',{attrs:{"arrow":"","max-width":160,"placement":_vm.Placement.TOP,"text":_vm.isVerified
              ? _vm.trans('tooltip.company_is_vat_payer', 'Uzņēmums ir PVN maksātājs')
              : _vm.trans('tooltip.cannot_verify_vat_status', 'Nevaram noteikt PVN maksātāja statusu')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-scale-transition',{staticClass:"cursor-pointer",attrs:{"group":"","hide-on-leave":"","leave-absolute":""}},[(_vm.isVerified)?_c('v-icon',{key:"verified",attrs:{"color":_vm.disabled ? 'silver' : 'primary',"small":""}},[_vm._v(" $checkBadge ")]):(_vm.formValue)?_c('v-icon',{key:"notfound",attrs:{"color":_vm.disabled ? 'silver' : 'warning',"small":""}},[_vm._v(" $warning ")]):_vm._e()],1)]},proxy:true}])})],1)]},proxy:true}],null,true),model:{value:(_vm.formValue),callback:function ($$v) {_vm.formValue=$$v},expression:"formValue"}}),_c('v-scale-transition',{attrs:{"hide-on-leave":"","leave-absolute":""}},[(_vm.canBeChecked(_vm.formValue))?_c('div',{staticClass:"flex items-center justify-end",class:{ 'cursor-not-allowed': _vm.isLoading }},[_c('v-btn',{staticClass:"primary--text font-weight-bold pa-0",attrs:{"plain":"","height":"16px","min-width":"0","ripple":false,"disabled":_vm.isLoading},on:{"click":function($event){return _vm.check(_vm.formValue ?? '')}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.trans('button.check', 'Pārbaudīt'))}})])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }