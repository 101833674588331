import { Component, Vue } from 'vue-property-decorator';
import DateFormat from '@/enums/config/DateFormat';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type IExchangeRate from '@/interfaces/IExchangeRate';
import HelperRepository from '@/repository/Helper';

@Component
export default class FetchCurrencyExchangeRateMixin extends Vue {
  public async FetchCurrencyExchangeRateMixin(
    currencyFrom: CurrencyISO,
    currencyTo: CurrencyISO,
    exchangeDate?: string,
  ): Promise<IExchangeRate> {
    try {
      return (await HelperRepository.fetchCurrencyExchangeRate(currencyFrom, currencyTo, exchangeDate)).data;
    } catch (_) {
      this.$logger('There was a problem to get currency exchange...');
      this.$logger('Default exchange rate is set to 0');
      const date: string = exchangeDate ?? this.parseNowToFormat(DateFormat.DATABASE);
      return {
        currency_from: currencyFrom,
        currency_to: currencyTo,
        exchange_date: date,
        exchange_rate: 0,
        start_date: date,
      };
    }
  }
}
