import DEFAULT_COMPANY_BRAND from '@/constants/mocks/company/CompanyBrand';
import DEFAULT_COMPANY_DETAILS from '@/constants/mocks/company/CompanyDetails';
import DEFAULT_DOCUMENT_SETTINGS from '@/constants/mocks/company/DocumentSettings';
import DEFAULT_PARTNER_FORM from '@/constants/mocks/forms/Partner';
import DEFAULT_STOCK_FORM from '@/constants/mocks/forms/Stock';
import type IDocument from '@/interfaces/document/IDocument';
import DocumentType from '@/enums/config/document/Type';
import DocumentModule from '@/enums/config/document/Module';
import DocumentStatus from '@/enums/config/document/Status';
import SignatureType from '@/enums/generics/SignatureType';

const DEFAULT_DOCUMENT_FORM: IDocument = {
  uuid: '',

  // Defines if this is one-time or recurring document
  is_template: false,

  // Defines if this is a final document
  is_final: false,

  category: DocumentModule.INCOME,

  // Document settings
  lang: DEFAULT_DOCUMENT_SETTINGS.default_document_locale,
  type: DocumentType.INVOICE,
  status: DocumentStatus.DRAFT,
  currency_code: DEFAULT_COMPANY_DETAILS.currency_code,

  // Boolean flags
  tax_by_group: DEFAULT_DOCUMENT_SETTINGS.tax_by_group,
  has_discount: false,
  has_multi_vat: false,

  // Document number settings
  number_iterator: 1,
  number_format: DEFAULT_DOCUMENT_SETTINGS.document_preferences.invoice.number_format,

  // Document dates & late fee
  date: '',
  due_date: '',
  late_fee_percent: DEFAULT_DOCUMENT_SETTINGS.use_late_fees ? DEFAULT_DOCUMENT_SETTINGS.default_late_fee_rate : null,

  // Document branding
  logo: DEFAULT_COMPANY_BRAND.logo,
  color: DEFAULT_COMPANY_BRAND.default_document_accent_color,

  // Document details
  sender: {
    uuid: '',

    name: DEFAULT_COMPANY_DETAILS.name,
    registration_number: DEFAULT_COMPANY_DETAILS.registration_number,
    vat_registration_number: DEFAULT_COMPANY_DETAILS.vat_registration_number,

    payment_methods: [],

    email: DEFAULT_COMPANY_DETAILS.email,
    address: DEFAULT_COMPANY_DETAILS.address,
    country: DEFAULT_COMPANY_DETAILS.country,
    website: DEFAULT_COMPANY_DETAILS.website,

    phone_prefix: DEFAULT_COMPANY_DETAILS.phone_prefix,
    phone_number: DEFAULT_COMPANY_DETAILS.phone_number,

    notes: DEFAULT_DOCUMENT_SETTINGS.document_preferences.invoice.default_supplier_notes,

    update_globally: false,
  },
  recipient: {
    ...DEFAULT_PARTNER_FORM,
    update_globally: false,
  },
  carrier: {
    name: '',
    registration_number: '',

    driver: '',
    vehicle_model: '',
    vehicle_plate: '',

    issuing_address: '',
    receipt_address: '',
  },

  // Goods & services
  items: [
    {
      ...DEFAULT_STOCK_FORM,
    },
  ],

  // Single VAT rate
  single_vat: null,

  // Previously paid amount
  additional_price_rows: [],

  // Totals
  vat_amount: 0.0,
  total_with_vat: 0.0,
  total_without_vat: 0.0,

  // Totals in local currency
  currency_rate: 1.0,
  vat_amount_local: 0.0,
  total_with_vat_local: 0.0,
  total_without_vat_local: 0.0,

  // Prepayment Terms & calculated final amount
  prepaid_amount: 0.0,
  final_amount: 0.0,

  // VAT reference on O VAT rate
  vat_reference: '',

  // Notes, payment & deal types
  payment_type: null,
  payment_type_custom: '',
  deal_type: null,
  deal_type_custom: '',
  notes: DEFAULT_DOCUMENT_SETTINGS.document_preferences.invoice.default_notes,

  // Signatures
  signature: {
    type: SignatureType.DIGITALLY_SIGNED,
    signatories: {
      sender: '',
      sender_sign_date: '',
      recipient: '',
      recipient_sign_date: '',
    },
  },

  // Document origin
  origin_record: {
    uuid: '',

    type: DocumentType.INVOICE,
    status: DocumentStatus.DRAFT,

    record_number: '',
    is_template: false,
  },

  // History logs
  logs: [],

  // Document owner data
  user: {
    uuid: '',
    first_name: '',
    last_name: '',
  },

  // Current user document permissions
  permissions: {
    delete: false,
    edit: false,
  },
};

export default DEFAULT_DOCUMENT_FORM;
