import type { AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class PrintDocumentMixin extends Vue {
  public async PrintDocumentMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const response: AxiosResponse = await DocumentRepository.download(companyUUID, documentUUID);

    // Create a new Blob object from the response data
    const blob: Blob = new Blob([response.data], { type: 'application/pdf;base64' });

    // Create a URL for the Blob object
    const url: string = window.URL.createObjectURL(blob);

    if (this.$isSafari()) {
      // Create a new iframe
      const iframe = document.createElement('iframe');

      // Set the iframe's source to the Blob URL and hide it
      iframe.src = url;
      iframe.style.display = 'none';

      // Append the iframe to the body of the document
      document.body.appendChild(iframe);

      // Wait for the iframe to load and call print on the iframe's content window
      iframe.onload = (): void => {
        iframe.contentWindow?.print();
      };
    } else {
      const newTab: any = window.open(url, '_blank');
      newTab.print();
    }
  }
}
