import app from '@/main';
import store from '@/store';
import useCopyObject from '@/services/CopyObject';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import DEFAULT_PAGINATION from '@/constants/mocks/Pagination';
import DEFAULT_URL_PARAMS from '@/constants/mocks/UrlParams';
import DocumentType from '@/enums/config/document/Type';
import DocumentRoute from '@/enums/config/document/Route';
import DocumentRouteName from '@/enums/config/router/Document';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import RecurringDocumentRoute from '@/enums/config/document/RecurringRoute';
import DashboardRouteName from '@/enums/config/router/Dashboard';
import RecurringRouteName from '@/enums/config/router/Recurring';
import Trigger from '@/enums/generics/Trigger';
import type DocumentStatus from '@/enums/config/document/Status';
import type SortDir from '@/enums/generics/SortDir';
import type SortField from '@/enums/generics/SortField';
import type IPagination from '@/interfaces/IPagination';
import type IUrlParams from '@/interfaces/IUrlParams';

@Module({
  store,
  name: 'Page',
  namespaced: true,
  dynamic: true,
})
class Page extends VuexModule {
  private pagination: IPagination = DEFAULT_PAGINATION;
  private urlParams: IUrlParams = DEFAULT_URL_PARAMS;
  private prevUrlParams: IUrlParams = DEFAULT_URL_PARAMS;

  private pageTrigger: Trigger = Trigger.NONE;

  private rememberUrlParams: boolean = false;

  public get getPagination(): IPagination {
    return this.pagination;
  }

  public get getUrlParams(): IUrlParams {
    return this.urlParams;
  }

  public get getPrevUrlParams(): IUrlParams {
    return this.prevUrlParams;
  }

  public get getRememberUrlParams(): boolean {
    return this.rememberUrlParams;
  }

  public get getPageStatuses(): DocumentStatus[] {
    return this.urlParams.statuses ?? [];
  }

  public get getPageSortBy(): SortField | undefined {
    return this.urlParams.sort_field;
  }

  public get getPageSortDir(): SortDir | undefined {
    return this.urlParams.sort_direction;
  }

  public get isEmpty(): boolean {
    return (
      this.urlParams.search === '' &&
      this.urlParams.statuses?.length === 0 &&
      this.urlParams.date_from === '' &&
      this.urlParams.date_to === ''
    );
  }

  public get getPageTrigger(): Trigger {
    return this.pageTrigger;
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_PAGINATION' })
  public async SET_PAGINATION(pagination: IPagination): Promise<IPagination> {
    return pagination;
  }

  @Action({ commit: 'UPDATE_PREV_URL_PARAMS' })
  public async SET_PREV_URL_PARAMS(urlParams: IUrlParams): Promise<IUrlParams> {
    return urlParams;
  }

  @Action({ commit: 'UPDATE_URL_PARAMS' })
  public async SET_URL_PARAMS(urlParams: Partial<IUrlParams>): Promise<IUrlParams> {
    const mergedUrlParams: IUrlParams = useCopyObject().copy(urlParams, this.urlParams);
    // if (this.prevUrlParams.page === mergedUrlParams.page) {
    //   mergedUrlParams.page = 1;
    // }
    await this.SET_PREV_URL_PARAMS(mergedUrlParams);
    return mergedUrlParams;
  }

  @Action({ commit: 'UPDATE_URL_PARAMS' })
  public async SET_URL_PARAMS_FROM_PREV(): Promise<IUrlParams> {
    return this.prevUrlParams;
  }

  @Action
  public async SET_MISSING_URL_PARAMS(urlParams: Partial<IUrlParams>): Promise<void> {
    const isMissing = Object.entries(urlParams).some(
      ([key, value]) => value !== this.urlParams[key as keyof IUrlParams],
    );

    if (isMissing) {
      await this.SET_URL_PARAMS(urlParams);
    }
  }

  @Action({ commit: 'UPDATE_PAGE_TRIGGER' })
  public async SET_PAGE_TRIGGER(trigger: Trigger): Promise<Trigger> {
    return trigger;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_PAGINATION' })
  public async CLEAR_PAGINATION(): Promise<IPagination> {
    return DEFAULT_PAGINATION;
  }

  @Action({ commit: 'UPDATE_URL_PARAMS' })
  public async CLEAR_URL_PARAMS(): Promise<IUrlParams> {
    return DEFAULT_URL_PARAMS;
  }

  @Action({ commit: 'UPDATE_PAGE_TRIGGER' })
  public async CLEAR_PAGE_TRIGGER(): Promise<Trigger> {
    return Trigger.NONE;
  }

  @Action({ commit: 'UPDATE_REMEMBER_URL_PARAMS' })
  public async REMEMBER_URL_PARAMS(): Promise<boolean> {
    return true;
  }

  @Action({ commit: 'UPDATE_REMEMBER_URL_PARAMS' })
  public async FORGET_URL_PARAMS(): Promise<boolean> {
    return false;
  }

  @Action
  public async RESET_PAGE(): Promise<void> {
    await this.CLEAR_PAGE_TRIGGER();
    await this.CLEAR_PAGINATION();
    await this.CLEAR_URL_PARAMS();
  }

  /*******   Helper action   *******/

  // Get document types from route query
  // This should be calculated based on the route name and params
  @Action
  public async GET_ROUTE_QUERY_TYPES(
    types: (DocumentType | RecurringDocumentType)[] = [],
  ): Promise<(DocumentType | RecurringDocumentType)[]> {
    if (types.length === 0) {
      if (app.$route.name === DashboardRouteName.LIST || app.$route.meta.parent === DocumentRouteName.INDEX) {
        switch (app.$route.params.document) {
          case DocumentRoute.WAYBILL:
            types.push(DocumentType.WAYBILL, DocumentType.CREDIT_NOTE);
            break;
          case DocumentRoute.OFFER:
            types.push(DocumentType.OFFER);
            break;
          case DocumentRoute.ADVANCE_INVOICE:
            types.push(DocumentType.ADVANCE_INVOICE);
            break;
          case DocumentRoute.FINANCIAL:
            types.push(DocumentType.INVOICE, DocumentType.WAYBILL, DocumentType.CREDIT_NOTE);
            break;
          case DocumentRoute.ALL:
            types.push(...Object.values(DocumentType));
            break;
          case DocumentRoute.INVOICE:
          default:
            types.push(DocumentType.INVOICE, DocumentType.CREDIT_NOTE);
            break;
        }
      }

      if (app.$route.name === RecurringRouteName.LIST || app.$route.meta.parent === RecurringRouteName.INDEX) {
        switch (app.$route.params.document) {
          case RecurringDocumentRoute.WAYBILL:
            types.push(RecurringDocumentType.WAYBILL);
            break;
          case RecurringDocumentRoute.OFFER:
            types.push(RecurringDocumentType.OFFER);
            break;
          case RecurringDocumentRoute.ADVANCE_INVOICE:
            types.push(RecurringDocumentType.ADVANCE_INVOICE);
            break;
          case RecurringDocumentRoute.INVOICE:
          default:
            types.push(RecurringDocumentType.INVOICE);
            break;
        }
      }
    }

    return types;
  }

  // Get route document param from route name and query types
  @Action
  public async GET_ROUTE_PARAM_DOCUMENT(): Promise<DocumentRoute | RecurringDocumentRoute> {
    const types = await this.GET_ROUTE_QUERY_TYPES(this.urlParams.types);

    if (app.$route.name === DashboardRouteName.LIST || app.$route.meta.parent === DocumentRouteName.INDEX) {
      if (app.isEqualWhenSorted(types, [DocumentType.INVOICE, DocumentType.CREDIT_NOTE])) {
        return DocumentRoute.INVOICE;
      }
      if (app.isEqualWhenSorted(types, [DocumentType.WAYBILL, DocumentType.CREDIT_NOTE])) {
        return DocumentRoute.WAYBILL;
      }
      if (app.isEqualWhenSorted(types, [DocumentType.OFFER])) {
        return DocumentRoute.OFFER;
      }
      if (app.isEqualWhenSorted(types, [DocumentType.ADVANCE_INVOICE])) {
        return DocumentRoute.ADVANCE_INVOICE;
      }
      if (app.isEqualWhenSorted(types, [DocumentType.INVOICE, DocumentType.WAYBILL, DocumentType.CREDIT_NOTE])) {
        return DocumentRoute.FINANCIAL;
      }
      if (app.isEqualWhenSorted(types, Object.values(DocumentType))) {
        return DocumentRoute.ALL;
      }
      return DocumentRoute.INVOICE;
    }

    if (app.$route.name === RecurringRouteName.LIST || app.$route.meta.parent === RecurringRouteName.INDEX) {
      if (app.isEqualWhenSorted(types, [RecurringDocumentType.INVOICE])) {
        return RecurringDocumentRoute.INVOICE;
      }
      if (app.isEqualWhenSorted(types, [RecurringDocumentType.WAYBILL])) {
        return RecurringDocumentRoute.WAYBILL;
      }
      if (app.isEqualWhenSorted(types, [RecurringDocumentType.OFFER])) {
        return RecurringDocumentRoute.OFFER;
      }
      if (app.isEqualWhenSorted(types, [RecurringDocumentType.ADVANCE_INVOICE])) {
        return RecurringDocumentRoute.ADVANCE_INVOICE;
      }
      return RecurringDocumentRoute.INVOICE;
    }

    return DocumentRoute.INVOICE;
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_PAGINATION(pagination: IPagination): Promise<IPagination> {
    return (this.pagination = pagination);
  }

  @Mutation
  public async UPDATE_URL_PARAMS(urlParams: IUrlParams): Promise<IUrlParams> {
    return (this.urlParams = urlParams);
  }

  @Mutation
  public async UPDATE_PREV_URL_PARAMS(urlParams: IUrlParams): Promise<IUrlParams> {
    return (this.prevUrlParams = urlParams);
  }

  @Mutation
  public async UPDATE_REMEMBER_URL_PARAMS(remember: boolean): Promise<boolean> {
    return (this.rememberUrlParams = remember);
  }

  @Mutation
  public async UPDATE_PAGE_TRIGGER(trigger: Trigger): Promise<Trigger> {
    return (this.pageTrigger = trigger);
  }
}

const PageModule = getModule(Page);

export default PageModule;
