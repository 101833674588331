import Repository from '@/repository/Index';
import Axios, { AxiosPromise } from 'axios';
import type CurrencyISO from '@/enums/config/iso/CurrencyISO';
import type ICheckVatStatusForm from '@/interfaces/forms/helper/ICheckVatStatusForm';
import type IRegistrySearchForm from '@/interfaces/forms/helper/IRegistrySearchForm';

export default class HelperRepository {
  // Check company vat payer/vat registration number status
  public static checkVatStatus(payload: ICheckVatStatusForm): AxiosPromise {
    return Repository.post('/helpers/check-vat-payer-status', payload);
  }

  // List business registry database
  public static listBusinessRegistry(payload: IRegistrySearchForm): AxiosPromise {
    return Repository.get('/app-data/company-registry/search', { params: payload });
  }

  // Fetch currecny exchnage rate (europe central bank - ecb)
  public static fetchCurrencyExchangeRate(
    currencyFrom: CurrencyISO,
    currencyTo: CurrencyISO,
    exchangeDate?: string,
  ): AxiosPromise {
    return Axios.get(
      `https://europe-central2-numbero-project.cloudfunctions.net/exchange-rate/${currencyFrom}/to/${currencyTo}`,
      {
        params: { startPeriod: exchangeDate },
      },
    );
  }

  // Find IBAN details from bank account number
  public static fetchIban(IBANnumber: string): AxiosPromise {
    return Repository.post('/banks/find-by-iban', { account_number: IBANnumber });
  }

  // Fetch user notifications (firebase rtdb)
  public static fetchNotifications(email: string, verified: boolean): AxiosPromise {
    return Axios.get('https://europe-central2-numbero-project.cloudfunctions.net/numbero-notifications', {
      params: { email, verified },
    });
  }

  // Update user notifications with has read status (firebase rtdb)
  public static updateNotifications(email: string, verified: boolean): AxiosPromise {
    return Axios.get('https://europe-central2-numbero-project.cloudfunctions.net/numbero-notifications/update', {
      params: { email, verified },
    });
  }
}
