<template>
  <validation-provider
    ref="validator"
    :rules="rules"
    :debounce="debounceValidation"
    v-slot="{ errors }"
    :name="label"
    :vid="name"
    tag="div"
    class="relative flex flex-col items-start gap-y-2 text-left"
  >
    <label
      v-if="showLabel && label"
      :for="name"
      class="float-left text-left text-inherit leading-none"
      :class="dark ? 'white--text' : 'black--text'"
    >
      <slot name="label" :label="label">
        <span v-text="label" />
      </slot>
    </label>
    <v-text-field
      v-model="formValue"
      v-bind="bind"
      v-on="on"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :clearable="clearable"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      :loading="isLoading || showProgress"
      hide-details="auto"
      :type="type"
      :name="name"
      :dark="dark"
      :id="name"
      :reverse="reverse"
      flat
      solo
      :color="textColor ?? (dark ? 'white' : 'eerie')"
      :background-color="color ?? (dark ? 'eerie' : 'white')"
      class="text-inherit w-full"
      :class="[{ 'rounded-lg': rounded }, { 'text-field-error': !!error(errors) && showErrorBorder }, inputClass]"
      @blur="blur"
      @focus="focus"
      @input="input"
      @change="change"
      @keydown="keydown"
      @keypress="keypress"
      @click:clear="clear"
    >
      <template #progress>
        <div v-if="isLoading" class="absolute bottom-0 left-0 right-0 w-full" :class="{ 'px-3': rounded }">
          <v-progress-linear indeterminate color="primary" height="2" :class="{ 'rounded-b-xl px-3': rounded }" />
        </div>
        <div v-if="showProgress" class="absolute bottom-0 left-0 right-0" :class="{ 'px-1': rounded }">
          <v-progress-linear
            :value="passwordStrength"
            :color="progressBarColor"
            height="5"
            :class="[`bg-${progressBarColor}-30 transition-all`, { 'rounded-b-xl px-3': rounded }]"
            style="margin-top: -5px"
          />
        </div>
      </template>
      <template #prepend>
        <slot name="prepend-outer" />
      </template>
      <template #prepend-inner>
        <slot name="prepend" />
      </template>
      <template #append>
        <template v-if="isPasswordInput">
          <v-btn icon small @click="showPassword = !showPassword">
            <v-icon size="20" v-text="appendIcon" />
          </v-btn>
        </template>
        <slot v-else name="append" />
      </template>
      <template #append-outer>
        <slot name="append-outer" />
      </template>
    </v-text-field>

    <v-scroll-y-transition>
      <div v-show="error(errors) && showError" class="text-caption error--text mt-n1" v-text="error(errors)" />
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <div
        v-show="!(error(errors) && showError) && isCapsLockOn"
        class="text-caption black--text mt-n1"
        v-text="trans('warning.caps_lock_on', 'Burtslēga taustiņš (Caps-lock) ir ieslēgts')"
      />
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <div
        v-show="!(error(errors) && showError) && !isCapsLockOn && hint"
        class="text-caption black--text mt-n1"
        v-text="hint"
      />
    </v-scroll-y-transition>
    <div v-if="disabled" class="absolute inset-0 cursor-not-allowed" />
  </validation-provider>
</template>

<script lang="ts">
  import throttle from 'lodash-es/throttle';
  import { Component, Emit, Prop, Ref, VModel, Vue } from 'vue-property-decorator';
  import { UPPERCASE, LOWERCASE, NUMBER, SPECIAL_CHARS, MIN_LENGTH } from '@/constants/regex/PasswordStrength';
  import type { ProviderInstance } from 'vee-validate/dist/types/types';
  import KeypressInputType from '@/enums/types/KeypressInputType';
  import TimeConfig from '@/enums/config/TimeConfig';

  @Component
  export default class TextField extends Vue {
    @VModel() formValue!: string | number | null;

    @Prop({ default: KeypressInputType.TEXT }) inputType!: KeypressInputType;
    @Prop({ default: 'text' }) fieldType?: HTMLInputElement['type'];

    @Prop({ default: 'primary' }) loadingColor!: string;
    @Prop({ default: 'off' }) autocomplete?: string;
    @Prop({ default: '' }) placeholder?: string;
    @Prop({ default: '' }) name?: string;
    @Prop({ default: '' }) hint?: string;
    @Prop({ default: '' }) label?: string;
    @Prop({ default: true }) showLabel?: boolean;
    @Prop({ default: true }) showError?: boolean;
    @Prop({ default: true }) showErrorBorder?: boolean;

    @Prop({ default: 0 }) debounceValidation?: number;
    @Prop({ default: '' }) rules?: string;

    @Prop({ type: Boolean }) clearable?: boolean;
    @Prop({ type: Boolean }) autofocus?: boolean;
    @Prop({ type: Boolean }) readonly?: boolean;
    @Prop({ type: Boolean }) disabled?: boolean;
    @Prop({ type: Boolean }) loading?: boolean;
    @Prop({ type: Boolean }) reverse?: boolean;

    @Prop({ type: Boolean }) capsLockWarning?: boolean;
    @Prop({ type: Boolean }) showProgress?: boolean;
    @Prop({ type: Boolean }) rounded?: boolean;
    @Prop({ type: Boolean }) dark?: boolean;

    @Prop() prepareInput?: (value: typeof this.formValue) => typeof value;

    @Prop() color?: string;
    @Prop() textColor?: string;
    @Prop() inputClass?: string;

    @Prop() bind?: Record<string, unknown>;
    @Prop() on?: Record<string, unknown>;

    @Ref('validator') validator!: ProviderInstance;

    public showPassword: boolean = false;
    public isCapsLockOn: boolean = false;

    private capsLockEvents: string[] = ['keypress', 'keydown', 'keyup'];
    private progressBarColors: string[] = ['error', 'warning', 'success'];
    private passwordValidity: RegExp[] = [UPPERCASE, LOWERCASE, NUMBER, SPECIAL_CHARS, MIN_LENGTH];

    /*****         computed       *****/

    public get isPasswordInput(): boolean {
      return this.fieldType === 'password';
    }

    public get type(): HTMLInputElement['type'] {
      if (this.isPasswordInput) {
        return this.showPassword ? 'text' : 'password';
      }
      return this.fieldType || 'text';
    }

    public get appendIcon(): string {
      if (this.isPasswordInput) {
        return this.showPassword ? '$eyeOff' : '$eye';
      }
      return '';
    }

    public get passwordStrength(): number {
      const value: string = (this.formValue ?? '').toString();

      const stepPercentage: number = 100 / this.passwordValidity.length;
      const passwordValidity: boolean[] = this.passwordValidity.map((regex) => regex.test(value));

      return passwordValidity.filter(Boolean).length * stepPercentage;
    }

    public get progressBarColor(): string {
      if ((this.formValue ?? '').toString().length) {
        const strength: number = this.passwordStrength;
        const colors = this.progressBarColors; // ['error', 'warning', 'success', ...]

        const thresholds = [60, 100]; // thresholds for changing colors

        const colorIndex = thresholds.findIndex((threshold) => strength < threshold);

        // If no threshold is found, return the last color
        return colors[colorIndex >= 0 ? colorIndex : colors.length - 1];
      }

      return 'transparent';
    }

    public get isLoading(): boolean | string {
      return this.loading ? this.loadingColor : false;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    @Emit('input:debounce')
    public async input(userInput: string): Promise<string> {
      if ((this.formValue ?? '') != (userInput ?? '')) {
        await this.updateQuery(userInput);
      }
      return userInput ?? '';
    }

    @Emit('query')
    public query(userInput: string): string {
      return userInput;
    }

    @Emit('change')
    public change(): void {}

    @Emit('keypress')
    public keypress(e: KeyboardEvent): void {
      switch (this.inputType) {
        case KeypressInputType.POSITIVE_INT:
          this.positiveInteger(e);
          return;
        case KeypressInputType.NEGATIVE_INT:
          this.negativeInteger(e);
          return;
        case KeypressInputType.POSITIVE_FLOAT:
          this.positiveFloat(e);
          return;
        case KeypressInputType.NEGATIVE_FLOAT:
          this.negativeFloat(e);
          return;
      }
    }

    @Emit('keydown')
    public keydown(e: KeyboardEvent): KeyboardEvent {
      return e;
    }

    @Emit('blur')
    public blur(e: FocusEvent): FocusEvent {
      return e;
    }

    @Emit('focus')
    public focus(e: FocusEvent): FocusEvent {
      if (this.prepareInput) {
        this.formValue = this.prepareInput(this.formValue);
      }
      return e;
    }

    @Emit('clear')
    public async clear(): Promise<void> {
      this.formValue = '';
      await this.updateQuery();
    }

    @Emit('error')
    public error(errors: any): string {
      if (errors.length) {
        return errors[0];
      }
      return '';
    }

    /*****         helpers        *****/

    private updateQuery = throttle(async function (this: TextField, query = ''): Promise<void> {
      this.query(query ?? '');
    }, TimeConfig.DEBOUNCE);

    private checkCapsLock(e: KeyboardEvent): void {
      this.isCapsLockOn = this.$checkCapsLock(e);
    }

    private addEventListeners() {
      this.capsLockEvents.forEach((event) => {
        window.addEventListener(event, (e) => this.checkCapsLock(e as KeyboardEvent));
      });
    }

    private removeEventListeners() {
      this.capsLockEvents.forEach((event) => {
        window.removeEventListener(event, (e) => this.checkCapsLock(e as KeyboardEvent));
      });
    }

    public async isValid(): Promise<boolean> {
      return (await this.validator.validate()).valid;
    }

    public setError(error: string[]): void {
      this.validator.setErrors(error);
    }

    public reset(): void {
      this.validator.reset();
      this.formValue = '';
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      if (this.capsLockWarning) {
        this.addEventListeners();
      }
    }

    beforeDestroy() {
      this.removeEventListeners();
    }
  }
</script>
