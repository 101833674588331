import app from '@/main';
import store from '@/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type Locale from '@/enums/config/Locale';
import type IReference from '@/interfaces/IReference';
import type IVatReference from '@/interfaces/IVatReference';
import UserModule from '@/store/modules/User';
import TranslationModule from '@/store/modules/Translation';
import ReferencesRepository from '@/repository/References';

@Module({
  store,
  name: 'Reference',
  namespaced: true,
  dynamic: true,
})
class Reference extends VuexModule {
  private references: IReference[] = [];
  private userReferences: IReference[] = [];

  public get getReferences(): IReference[] {
    return this.references;
  }

  public get getUserReferences(): IReference[] {
    return this.userReferences;
  }

  public get getDefaultUserReference(): string {
    return this.userReferences.find((ref) => ref.is_default)?.title || '';
  }

  /*******   Set action   *******/

  @Action({ commit: 'UPDATE_REFERENCES' })
  public async SET_REFERENCES(locale: Locale = TranslationModule.getLocale): Promise<IReference[]> {
    let references: IReference[] = [];
    try {
      references = (await ReferencesRepository.listAppDefined()).data.map((reference: IVatReference) => {
        return {
          uuid: reference.id.toString(),
          title: reference.reference[locale],
          category: reference.option[locale],
          is_default: false,
        };
      });
    } catch (e) {
      app.$logger('unable to fetch references...');
    }
    return references;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async SET_USER_REFERENCES(companyUUID: string = UserModule.getCompanyUUID): Promise<IReference[]> {
    let userReferences: IReference[] = [];
    try {
      userReferences = (await ReferencesRepository.list(companyUUID)).data;
    } catch (e) {
      app.$logger('unable to fetch references...');
    }
    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async ADD_USER_REFERENCE(userReference: IReference): Promise<IReference[]> {
    const userReferences = [...this.userReferences];
    userReferences.push(userReference);
    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async UPDATE_USER_REFERENCE(userReference: IReference): Promise<IReference[]> {
    const userReferences = [...this.userReferences];
    const index = userReferences.findIndex((ref) => ref.uuid === userReference.uuid);
    if (index > -1) {
      userReferences.splice(index, 1, userReference);
    }
    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async SET_DEFAULT_USER_REFERENCE(referenceUUID: string): Promise<IReference[]> {
    const userReferences = [...this.userReferences];

    // Set all references to not default
    userReferences.forEach((ref) => {
      ref.is_default = false;
    });

    // Find the specified reference and set it as default
    const index = userReferences.findIndex((ref) => ref.uuid === referenceUUID);
    if (index > -1) {
      userReferences[index].is_default = true;
    }

    return userReferences;
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async DELETE_USER_REFERENCE(referenceUUID: string): Promise<IReference[]> {
    const userReferences = [...this.userReferences];
    const index = userReferences.findIndex((ref) => ref.uuid === referenceUUID);
    if (index > -1) {
      userReferences.splice(index, 1);
    }
    return userReferences;
  }

  /*******   Unset action   *******/

  @Action({ commit: 'UPDATE_REFERENCES' })
  public async CLEAR_REFERENCES(): Promise<IReference[]> {
    return [];
  }

  @Action({ commit: 'UPDATE_USER_REFERENCES' })
  public async CLEAR_USER_REFERENCES(): Promise<IReference[]> {
    return [];
  }

  /*******   Update mutation   *******/

  @Mutation
  public async UPDATE_REFERENCES(references: IReference[]): Promise<IReference[]> {
    return (this.references = references);
  }

  @Mutation
  public async UPDATE_USER_REFERENCES(userReferences: IReference[]): Promise<IReference[]> {
    return (this.userReferences = userReferences);
  }
}

const ReferenceModule = getModule(Reference);

export default ReferenceModule;
