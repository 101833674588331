import Repository from '@/repository/Index';
import type IUrlParams from '@/interfaces/IUrlParams';
import { AxiosPromise } from 'axios';

export default class ChartRepository {
  // Retrieve document chart data
  public static documentChart(companyUUID: string, params: IUrlParams): AxiosPromise {
    return Repository.get('/companies/' + companyUUID + '/bookkeeping-records/chart', { params });
  }
}
