import type { AxiosResponse } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DownloadDocumentMixin extends Vue {
  public async DownloadDocumentInMemoryMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<File> {
    const response: AxiosResponse = await DocumentRepository.download(companyUUID, documentUUID);

    // Extract the document name from the response headers
    // And create a new Blob object from the response data
    const documentName = response.headers['content-disposition']
      .split('filename=')[1]
      .split(';')[0]
      .replace(/[^a-z0-9.]/gi, '_')
      .replace(/^_+|_+$/g, '');
    const blob = new Blob([response.data], { type: 'application/pdf' });

    return new File([blob], documentName, { type: 'application/pdf' });
  }

  public async DownloadDocumentInBrowserMixin(
    documentUUID: string,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const file: File = await this.DownloadDocumentInMemoryMixin(documentUUID, companyUUID);

    // Create a URL for the Blob object
    const url: string = window.URL.createObjectURL(file);

    // Create a new anchor element and set the download attribute to the document name
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.style.display = 'none';

    // Append the anchor element to the document body and click it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the URL to free up memory
    URL.revokeObjectURL(url);
  }
}
