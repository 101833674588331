import { Component, Vue } from 'vue-property-decorator';
import DateFormat from '@/enums/config/DateFormat';
import FileType from '@/enums/config/FileType';
import type IDocumentReportForm from '@/interfaces/forms/document/IDocumentReportForm';
import type IDocumentBulkReportForm from '@/interfaces/forms/document/IDocumentBulkReportForm';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';

@Component
export default class DocumentReportMixin extends Vue {
  public async DocumentReportMixin(
    payload: IDocumentReportForm | IDocumentBulkReportForm,
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<void> {
    const responseData = (await DocumentRepository.overviewReport(companyUUID, payload)).data;

    // Set report name
    const title = this.trans('workspace.title.summary', 'Pārskats')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const currentDate = this.parseNowToFormat(DateFormat.DATABASE);
    const reportFormat = [FileType.ZALKTIS, FileType.JUMIS].includes(payload.format) ? 'xml' : payload.format;
    const reportName = `${title}-${currentDate}.${reportFormat}`;

    // Mapping object for file types and their corresponding content types
    const fileTypeToContentType = {
      [FileType.PDF]: 'application/pdf',
      [FileType.XLS]: 'application/vnd.ms-excel',
      [FileType.XLSX]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      [FileType.ZALKTIS]: 'application/xml',
      [FileType.JUMIS]: 'application/xml',
    };

    // Create invisible clickable link to download report
    const blob: Blob = new Blob([responseData], { type: fileTypeToContentType[payload.format] });
    const url: string = window.URL.createObjectURL(blob);

    // Create a new anchor element and set the download attribute to the document name
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = reportName;
    link.style.display = 'none';

    // Append the anchor element to the document body and click it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the URL to free up memory
    window.URL.revokeObjectURL(url);
  }
}
