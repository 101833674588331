import { Component, Mixins } from 'vue-property-decorator';
import DocumentRoute from '@/enums/config/document/Route';
import DocumentType from '@/enums/config/document/Type';
import RecurringDocumentType from '@/enums/config/document/RecurringType';
import type Locale from '@/enums/config/Locale';
import type DateRange from '@/enums/config/DateRange';
import type DocumentActivity from '@/enums/config/document/Activity';
import type DocumentStatus from '@/enums/config/document/Status';
import type RecurringDocumentRoute from '@/enums/config/document/RecurringRoute';
import type { IRecurringConfig } from '@/interfaces/document/IRecurringDocument';
import type TemplateKey from '@/enums/config/email/TemplateKey';
import type DocumentPermission from '@/enums/config/permission/Document';
import type UserRole from '@/enums/config/UserRole';
import type BankType from '@/enums/generics/BankType';
import type CompanyType from '@/enums/generics/CompanyType';
import type DealType from '@/enums/generics/DealType';
import type PaymentType from '@/enums/generics/PaymentType';
import type PeriodType from '@/enums/config/PeriodType';
import type SignatureType from '@/enums/generics/SignatureType';
import type SortField from '@/enums/generics/SortField';
import type SortDir from '@/enums/generics/SortDir';
import type Unit from '@/enums/generics/Unit';
import type IPendingUser from '@/interfaces/multiuser/IPendingUser';
import type ICompanyDocumentSettings from '@/interfaces/translate/ICompanyDocumentSettings';
import type ICompanyEmailSettings from '@/interfaces/translate/ICompanyEmailSettings';
import type ITranslateMixin from '@/interfaces/mixins/ITranslateMixin';
import TranslationModule from '@/store/modules/Translation';
import TranslateBankTypeMixin from '@/mixins/translate/BankType';
import TranslateCompanyDocumentSettingsMixin from '@/mixins/translate/CompanyDocumentSettings';
import TranslateCompanyEmailSettingsMixin from '@/mixins/translate/CompanyEmailSettings';
import TranslateCompanyTypeMixin from '@/mixins/translate/CompanyType';
import TranslateDateRangeMixin from '@/mixins/translate/DateRange';
import TranslateDealTypeMixin from '@/mixins/translate/DealType';
import TranslateDocumentActivityMixin from '@/mixins/translate/DocumentActivity';
import TranslateDocumentDateMixin from '@/mixins/translate/DocumentDate';
import TranslateDocumentNumberMixin from '@/mixins/translate/DocumentNumber';
import TranslateDocumentNumberAndDateMixin from '@/mixins/translate/DocumentNumberAndDate';
import TranslateDocumentRouteMixin from '@/mixins/translate/DocumentRoute';
import TranslateDocumentStatusMixin from '@/mixins/translate/DocumentStatus';
import TranslateDocumentTypeMixin from '@/mixins/translate/DocumentType';
import TranslateEmailTemplateKeyMixin from '@/mixins/translate/EmailTemplateKey';
import TranslateGenericDayCountMixin from '@/mixins/translate/GenericDayCount';
import TranslateLanguageMixin from '@/mixins/translate/Language';
import TranslateNextRecurringMixin from '@/mixins/translate/NextRecurring';
import TranslatePaidWithDocumentMixin from '@/mixins/translate/PaidWithDocument';
import TranslatePaymentTypeMixin from '@/mixins/translate/PaymentType';
import TranslatePermissionMixin from '@/mixins/translate/Permission';
import TranslateRecurringIterationCountMixin from '@/mixins/translate/RecurringIterationCount';
import TranslateRecurringIterationPeriodMixin from '@/mixins/translate/RecurringIterationPeriod';
import TranslateRecurringPeriodTypeMixin from '@/mixins/translate/RecurringPeriodType';
import TranslateRecurringTypeMixin from '@/mixins/translate/RecurringType';
import TranslateSendDocumentsMixin from '@/mixins/translate/SendDocuments';
import TranslateSigantureTypeMixin from '@/mixins/translate/SignatureType';
import TranslateSortFieldMixin from '@/mixins/translate/SortField';
import TranslateUnitMixin from '@/mixins/translate/Unit';
import TranslateUserRoleMixin from '@/mixins/translate/UserRole';

@Component
export default class TranslateMixin extends Mixins<ITranslateMixin>(
  TranslateBankTypeMixin,
  TranslateCompanyDocumentSettingsMixin,
  TranslateCompanyEmailSettingsMixin,
  TranslateCompanyTypeMixin,
  TranslateDateRangeMixin,
  TranslateDealTypeMixin,
  TranslateDocumentActivityMixin,
  TranslateDocumentDateMixin,
  TranslateDocumentNumberMixin,
  TranslateDocumentNumberAndDateMixin,
  TranslateDocumentRouteMixin,
  TranslateDocumentStatusMixin,
  TranslateDocumentTypeMixin,
  TranslateEmailTemplateKeyMixin,
  TranslateGenericDayCountMixin,
  TranslateLanguageMixin,
  TranslateNextRecurringMixin,
  TranslatePaidWithDocumentMixin,
  TranslatePaymentTypeMixin,
  TranslatePermissionMixin,
  TranslateRecurringIterationCountMixin,
  TranslateRecurringIterationPeriodMixin,
  TranslateRecurringPeriodTypeMixin,
  TranslateRecurringTypeMixin,
  TranslateSendDocumentsMixin,
  TranslateSigantureTypeMixin,
  TranslateSortFieldMixin,
  TranslateUnitMixin,
  TranslateUserRoleMixin,
) {
  // Translate bank type - use TranslateBankTypeMixin
  public translateBankType(type: BankType, name = '', locale = TranslationModule.getLocale): string {
    return this.TranslateBankTypeMixin(type, name, locale);
  }

  // Translate bank type - use TranslateBankTypeDescriptionMixin
  public translateBankTypeDescription(type: BankType, locale = TranslationModule.getLocale): string {
    return this.TranslateBankTypeDescriptionMixin(type, locale);
  }

  // Translate bank type - use TranslateBankTypeFieldMixin
  public translateBankTypeField(type: BankType, locale = TranslationModule.getLocale): string {
    return this.TranslateBankTypeFieldMixin(type, locale);
  }

  // Translate company document settings - use TranslateCompanyDocumentSettingsMixin
  public translateCompanyDocumentSettings(
    type: DocumentType,
    locale = TranslationModule.getLocale,
  ): ICompanyDocumentSettings {
    return this.TranslateCompanyDocumentSettingsMixin(type, locale);
  }

  // Translate company email settings - use TranslateCompanyEmailSettingsMixin
  public translateCompanyEmailSettings(
    type: DocumentType,
    locale = TranslationModule.getLocale,
  ): ICompanyEmailSettings {
    return this.TranslateCompanyEmailSettingsMixin(type, locale);
  }

  // Translate date period key - use TranslateDateRangeMixin
  public translateDateRange(range: DateRange, locale = TranslationModule.getLocale): string {
    return this.TranslateDateRangeMixin(range, locale);
  }

  // Translate deal type - use TranslateDealTypeMixin
  public translateDealType(type: DealType, locale = TranslationModule.getLocale): string {
    return this.TranslateDealTypeMixin(type, locale);
  }

  // Translate document activity - use TranslateDocumentActivityMixin
  public translateDocumentActivity(activity: DocumentActivity, locale = TranslationModule.getLocale): string {
    return this.TranslateDocumentActivityMixin(activity, locale);
  }

  // Translate document date - use TranslateDocumentDateMixin
  public translateDocumentDate(
    type: DocumentType | RecurringDocumentType,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentDateMixin(type, locale);
  }

  // Translate document due date - use TranslateDocumentDueDateMixin
  public translateDocumentDueDate(
    type: DocumentType | RecurringDocumentType,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentDueDateMixin(type, locale);
  }

  // Translate document number - use TranslateDocumentNumberMixin
  public translateDocumentNumber(
    type: DocumentType | RecurringDocumentType,
    isFinal: boolean = false,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentNumberMixin(type, isFinal, locale);
  }

  // Translate document number and date - use TranslateDocumentNumberAndDateMixin
  public translateDocumentNumberAndDate(
    type: DocumentType | RecurringDocumentType,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentNumberAndDateMixin(type, locale);
  }

  // Translate document route - use TranslateDocumentRouteMixin
  public translateDocumentRoute(route: DocumentRoute, locale = TranslationModule.getLocale): string {
    return this.TranslateDocumentRouteMixin(route, locale);
  }

  // Translate recurring document route - use TranslateRecurringDocumentRouteMixin
  public translateRecurringDocumentRoute(route: RecurringDocumentRoute, locale = TranslationModule.getLocale): string {
    return this.TranslateRecurringDocumentRouteMixin(route, locale);
  }

  // Translate document status - use TranslateDocumentStatusByRouteMixin
  public translateDocumentStatusByRoute(
    status: DocumentStatus,
    route = DocumentRoute.INVOICE,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentStatusByRouteMixin(status, route, locale);
  }

  // Translate document status - use TranslateDocumentStatusByTypeMixin
  public translateDocumentStatusByType(
    status: DocumentStatus,
    type = DocumentType.INVOICE,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentStatusByTypeMixin(status, type, locale);
  }

  // Translate document type - use TranslateDocumentTypeMixin
  public translateDocumentType(
    type: DocumentType | RecurringDocumentType,
    isFinal: boolean = false,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateDocumentTypeMixin(type, isFinal, locale);
  }

  // Translate email template key - use TranslateEmailTemplateKeyMixin
  public translateEmailTemplateKey(key: TemplateKey, locale = TranslationModule.getLocale): string {
    return this.TranslateEmailTemplateKeyMixin(key, locale);
  }

  // Translate generic day count - use TranslateGenericDayCountMixin
  public translateGenericDayCount(num: number | null, locale = TranslationModule.getLocale): string {
    return this.TranslateGenericDayCountMixin(num, locale);
  }

  // Translate locale language - use TranslateLanguageMixin
  public translateLanguage(lang: Locale, locale = TranslationModule.getLocale): string {
    return this.TranslateLanguageMixin(lang, locale);
  }

  // Translate next recurring document - use TranslateNextRecurringMixin
  public translateNextRecurring(route: RecurringDocumentRoute, locale = TranslationModule.getLocale): string {
    return this.TranslateNextRecurringMixin(route, locale);
  }

  // Translate paid with document - use TranslatePaidWithDocumentMixin
  public translatePaidWithDocument(
    type: RecurringDocumentType | DocumentType,
    isFinal: boolean,
    record_number: string,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslatePaidWithDocumentMixin(type, isFinal, record_number, locale);
  }

  // Translate paid with ins ystem locale - use TranslatePaidWithInSystemLocaleMixin
  public async translatePaidWithInSystemLocale(desc: string, locale = TranslationModule.getLocale): Promise<string> {
    return await this.TranslatePaidWithInSystemLocaleMixin(desc, locale);
  }

  // Translate paid with in document locale - use TranslatePaidWithInDocumentLocaleMixin
  public async translatePaidWithInDocumentLocale(desc: string, locale = TranslationModule.getLocale): Promise<string> {
    return await this.TranslatePaidWithInDocumentLocaleMixin(desc, locale);
  }

  // Translate payment type - use TranslatePaymentTypeMixin
  public translatePaymentType(type: PaymentType, locale = TranslationModule.getLocale): string {
    return this.TranslatePaymentTypeMixin(type, locale);
  }

  // Translate permission - use TranslatePermissionMixin
  public translatePermission(
    role: UserRole,
    permissions: DocumentPermission[],
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslatePermissionMixin(role, permissions, locale);
  }

  // Describe given permissions - use DescribeGivenPermissionsMixin
  public describeGivenPermissions(user: IPendingUser, locale = TranslationModule.getLocale): string {
    return this.DescribeGivenPermissionsMixin(user, locale);
  }

  // Translate recurring remaining iterations - use TranslateRecurringIterationCountMixin
  public translateRecurringIterationCount(config: IRecurringConfig, locale = TranslationModule.getLocale): string {
    return this.TranslateRecurringIterationCountMixin(config, locale);
  }

  // Translate recurring iteration period - use TranslateRecurringIterationPeriodMixin
  public translateRecurringIterationPeriodPeriod(
    config: IRecurringConfig,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateRecurringIterationPeriodMixin(config, locale);
  }

  // Translate recurring period type - use TranslateRecurringPeriodTypeMixin
  public translateRecurringPeriodType(
    type: PeriodType,
    usePlural = false,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateRecurringPeriodTypeMixin(type, usePlural, locale);
  }

  // Translate recurring type - use TranslateRecurringTypeMixin
  public translateRecurringType(
    type: RecurringDocumentType | DocumentType,
    locale = TranslationModule.getLocale,
  ): string {
    return this.TranslateRecurringTypeMixin(type, locale);
  }

  // Translate company name field - use TranslatePartnerNameFieldMixin
  public translateCompanyNameField(type: CompanyType, locale = TranslationModule.getLocale): string {
    return this.TranslateCompanyNameFieldMixin(type, locale);
  }

  // Translate company registration number field - use TranslateCompanyTaxNumberFieldMixin
  public translateCompanyTaxNumberField(type: CompanyType, locale = TranslationModule.getLocale): string {
    return this.TranslateCompanyTaxNumberFieldMixin(type, locale);
  }

  // Translate send documents - use TranslateSendDocumentsMixin
  public translateSendDocuments(documentTypes: DocumentType[], locale = TranslationModule.getLocale): string {
    return this.TranslateSendDocumentsMixin(documentTypes, locale);
  }

  // Translate signature type - use TranslateSigantureTypeMixin
  public translateSignatureType(type: SignatureType, locale = TranslationModule.getLocale): string {
    return this.TranslateSigantureTypeMixin(type, locale);
  }

  // Translate sort field - use TranslateSortFieldMixin
  public translateSortField(sortField: SortField, sortDir: SortDir, locale = TranslationModule.getLocale): string {
    return this.TranslateSortFieldMixin(sortField, sortDir, locale);
  }

  // Translate unit - use TranslateUnitMixin
  public translateUnit(unit: Unit | string, locale = TranslationModule.getLocale): string {
    return this.TranslateUnitMixin(unit, locale);
  }

  // Translate user role - use TranslateUserRoleMixin
  public translateUserRole(role: UserRole, locale = TranslationModule.getLocale): string {
    return this.TranslateUserRoleMixin(role, locale);
  }
}
