import cloneDeep from 'lodash-es/cloneDeep';
import { Component, Vue } from 'vue-property-decorator';
import DEFAULT_DOCUMENT_FORM from '@/constants/mocks/forms/Document';
import DateFormat from '@/enums/config/DateFormat';
import type { IDocumentPreference } from '@/interfaces/company/IDocumentSettings';
import type IDocument from '@/interfaces/document/IDocument';
import CompanyModule from '@/store/modules/Company';
import DocumentModule from '@/store/modules/Document';

@Component
export default class InitDocumentTypeMixin extends Vue {
  public async InitDocumentTypeMixin(): Promise<IDocument> {
    const document: IDocument = cloneDeep({ ...DEFAULT_DOCUMENT_FORM, ...DocumentModule.getDocument });

    const documentPreferences: IDocumentPreference = await CompanyModule.GET_DOCUMENT_PREFERENCES(document.type);
    const dueDays = documentPreferences.default_due_days;

    document.number_format = documentPreferences.number_format;

    document.date = document.date || this.parseNowToFormat(DateFormat.LARAVEL);
    document.due_date = dueDays ? this.addPeriodToFormat(document.date, DateFormat.LARAVEL, dueDays, 'days') : '';

    // Notes should be copied only if the document is not a copy
    if (!DocumentModule.isCopyDocument) {
      document.notes = documentPreferences.default_notes;
    }

    return cloneDeep(document);
  }
}
